import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CfHubSpotService } from '../../services/cf-hubspot.service';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-user-guide-page',
  templateUrl: './user-guide-page.component.html',
  styleUrls: ['./user-guide-page.component.css']
})
export class UserGuidePageComponent implements OnInit {

  question1Hidden = true;
  question2Hidden = true;
  question3Hidden = true;
  question4Hidden = true;
  question5Hidden = true;
  question6Hidden = true;
  question7Hidden = true;
  question8Hidden = true;
  question9Hidden = true;
  question10Hidden = true;
  question11Hidden = true;

  userGuideForm = new FormGroup({});  
  submitQuestionForm = new FormGroup({});
  isSubmitted = false;
  /* Master Agents List */
  MasterAgents: any = ['Intelisys','Innovative Business Solutions','Pax 8', 'Avant', 'AppDirect', 'Telarus', 'None/Not Listed'];

  constructor(public fb: FormBuilder, private titleService: Title, private cfSendRequest: CfHubSpotService) { }

  ngOnInit(): void {
    this.titleService.setTitle('User Guide');

    this.userGuideForm = this.fb.group({
      "cfuserguidefirstname" : ["", Validators.required],
      "cfuserguidelastname" : ["", Validators.required],
      "cfuserguideemail" : ["", [ Validators.required, Validators.email ]],
      "cfuserguidecompany" : ["", Validators.required],
      "cfuserguidemasteragent" : ["", Validators.required],
      "cfuserguidephone" : ["", Validators.required]
    })

    this.submitQuestionForm = this.fb.group({
      "cfsubmitquestionfirstname" : ["", Validators.required],
      "cfsubmitquestionlastname" : ["", Validators.required],
      "cfsubmitquestionemail" : ["", [ Validators.required, Validators.email ]],
      "cfsubmitquestioncompany" : ["", Validators.required],
      "cfsubmitquestionmasteragent" : ["", Validators.required],
      "cfsubmitquestionphone" : ["", Validators.required],
      "cfsubmitquestionmessage" : [""]
    })
  }

  // convenience getter for easy access to form fields
  get d() { return this.userGuideForm.controls; }

  get q() { return this.submitQuestionForm.controls; }

  submitUserGuide(){
    this.isSubmitted = true;
    if(this.userGuideForm.valid){      
      //var datenow = new Date();
      //var utcms = Math.floor(datenow.getTime()/ 1000);

      let payload = {
        //"submittedAt": utcms + '000',
        "fields": [
          {
            "name": "firstname",
            "value": this.userGuideForm.controls['cfuserguidefirstname'].value
          },
          {
            "name": "lastname",
            "value": this.userGuideForm.controls['cfuserguidelastname'].value
          },
          {
            "name": "email",
            "value": this.userGuideForm.controls['cfuserguideemail'].value
          },
          {
            "name": "company",
            "value": this.userGuideForm.controls['cfuserguidecompany'].value
          },
          {
            "name": "which_master_agent_referred_you_to_cablefinder_",
            "value": this.userGuideForm.controls['cfuserguidemasteragent'].value
          },
          {
            "name": "phone",
            "value": this.userGuideForm.controls['cfuserguidephone'].value
          }
        ]
      }

      this.cfSendRequest.sendCFUserGuideRequest(payload)
        .subscribe(res =>{
          //console.log(res);
          alert("Thank you for requesting access to the CableFinder User Guide. You will be receiving an email shortly.");
        },
        (error) => {       
          alert("Error in form submission! Kindly re-submit with valid data. Thank you.");
        })

      this.userGuideForm.reset();
    }
  }


  submitUserQuestion(){
    this.isSubmitted = true;
    if(this.submitQuestionForm.valid){      
      //var datenow = new Date();
      //var utcms = Math.floor(datenow.getTime()/ 1000);

      let payload = {
        //"submittedAt": utcms + '000',
        "fields": [
          {
            "name": "firstname",
            "value": this.submitQuestionForm.controls['cfsubmitquestionfirstname'].value
          },
          {
            "name": "lastname",
            "value": this.submitQuestionForm.controls['cfsubmitquestionlastname'].value
          },
          {
            "name": "email",
            "value": this.submitQuestionForm.controls['cfsubmitquestionemail'].value
          },
          {
            "name": "company",
            "value": this.submitQuestionForm.controls['cfsubmitquestioncompany'].value
          },
          {
            "name": "which_master_agent_referred_you_to_cablefinder_",
            "value": this.submitQuestionForm.controls['cfsubmitquestionmasteragent'].value
          },
          {
            "name": "phone",
            "value": this.submitQuestionForm.controls['cfsubmitquestionphone'].value
          },
          {
            "name": "message",
            "value": this.submitQuestionForm.controls['cfsubmitquestionmessage'].value
          }
        ]
      }

      this.cfSendRequest.sendCFUserQuestion(payload)
        .subscribe(res =>{
          console.log(res);
          alert("Thank you for requesting more information on CableFinder. Someone from the CableFinder team will be reaching out to you shortly.");
        },
        (error) => {       
          alert("Error in form submission! Kindly re-submit with valid data. Thank you.");
        })

      this.userGuideForm.reset();
    }
  }
  openPdf(bookName: any) {
    const pdfUrl = `./assets/e-books/${bookName}`;
    const pdfName = bookName;
    window.open(pdfUrl, pdfName);
  }
  downloadPdf() {
    const pdfUrl = './assets/e-books/CableFinder Quick Guide.pdf';
    const pdfName = 'CableFinder Quick Guide';
    FileSaver.saveAs(pdfUrl, pdfName);
  }

}
