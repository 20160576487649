import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule,FormGroup, Validators } from '@angular/forms';
import { CfHubSpotService } from '../../services/cf-hubspot.service';
import { Router } from '@angular/router';
import { CfSalesforceService } from 'src/app/services/cf-salesforce.service';
import { ToastService } from 'src/app/services/toast-service';

@Component({
  selector: 'app-cf-register-form',
  templateUrl: './cf-register-form.component.html',
  styleUrls: ['./cf-register-form.component.css']
})
export class CFRegisterFormComponent implements OnInit {
  cablefinderRegisterForm = new FormGroup({});
  isSubmitted = false;
  submitSuccess = false;
  formHeading = '';
  getStarted = false;
  nextSteps = false;
  thankyouDescription = '';
  notPartneredDescription = '';

  /* Master Agents List */
  MasterAgents: any = ['AppDirect', 'Avant', 'Innovative Business Solutions', 'Intelisys', 'Pax 8', 'Telarus', 'None/Not Listed'];
 
  constructor(public fb: FormBuilder, private cfRegister: CfHubSpotService, private router: Router, public cfSalesforceService: CfSalesforceService, public toastService: ToastService) { }

  ngOnInit(): void {

    if(this.router.url == '/next-steps'){
      this.formHeading = 'Get In Touch With Our Team';
      this.nextSteps = true;
      this.thankyouDescription = 'Thank you for completing the form to learn more about CableFinder.Here\'s what you can expect as next steps. If you selected a Master Agent when completing the form, your contact information will be forwarded directly to their team to establish login credentials and be trained on the tool.';
      this.notPartneredDescription = 'If you aren\'t currently working with a Master Agent or selected \'Not Listed\' completing the form, someone from the CableFinder team will be emailing you to schedule some time for a demo of the tool and any question you may have. To gain access to the tool, you do need to work with one of our Master Agent partners. We encourage you to research the Master Agents prior to our conversation to determine the best fit for your company. We\'ll help make the introduction to their team and get you set up with login credentials! In the meantime, you can familiarize yourself with the tool with some of our helpful links and resources below.';
    } 
    else{
      this.formHeading = 'Looking to Enable Your Organization With CableFinder?';
      this.getStarted = true;
      this.thankyouDescription = 'Thank you for completing the form to learn more about CableFinder. Someone from the CableFinder team will be in touch with you very soon. In the meantime, check out the links below to Master Agents we\'re currently partnered with, as well as our other resources and links we\'ve published that may help to answer any of your questions.';
      this.notPartneredDescription = 'If you aren\'t currently working with a Master Agent or selected \'Not Listed\' completing the form, someone from the CableFinder team will be reaching out to you to see which Master Agent we can put you in touch with. Currently, in order to access our technology, you do need to be partnered with one of our Master Agents. In the meantime, refer to the links below as well in case those may answer your questions.';
    }
    
    this.cablefinderRegisterForm = this.fb.group({
      "cfregistermasteragent" : ["", Validators.required],
      "cfregisterfirstname" : ["", Validators.required],
      "cfregisterlastname" : ["", Validators.required],
      "cfregisteremail" : ["", Validators.required],
      "cfregisterphone" : ["", Validators.required],
      "cfregistercompany" : ["", Validators.required],
      "cfregistermessage" : ["", Validators.required]  
    })
  }

  onSubmit(){
    alert(this.cablefinderRegisterForm.controls['cf-register-firstname'].value);
  }

  // convenience getter for easy access to form fields
  get f() { return this.cablefinderRegisterForm.controls; }

  submitCFRegister(){
    this.isSubmitted = true;
    if(this.cablefinderRegisterForm.valid){
      let payload = {
        Distributor: this.cablefinderRegisterForm.controls['cfregistermasteragent'].value,
        FirstName: this.cablefinderRegisterForm.controls['cfregisterfirstname'].value,
        LastName: this.cablefinderRegisterForm.controls['cfregisterlastname'].value,
        Email: this.cablefinderRegisterForm.controls['cfregisteremail'].value,
        Phone: this.cablefinderRegisterForm.controls['cfregisterphone'].value,
        Company: this.cablefinderRegisterForm.controls['cfregistercompany'].value,
        Message: this.cablefinderRegisterForm.controls['cfregistermessage'].value
      }

      this.cfSalesforceService.sendCFRegisterFormRequest(payload)
      .subscribe((res) => {
        this.toastService.show(res?.response, { classname: 'bg-success text-light', delay: 10000 });
        // alert("Thank you for requesting more information about setting your team up on CableFinder.\r\nSomeone from the CableFinder team will be reaching out to you shortly.");      
        this.cablefinderRegisterForm.reset();
      },
      (error) => {          
        this.toastService.show(error.error?.response, { classname: 'bg-danger text-light', delay: 10000 }); 
        // alert("Error in form submission! Kindly re-submit with valid data. Thank you.");
        throw error;
      })
      
      this.isSubmitted = false;
    }
  }

}
