import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SlidesOutputData, OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-events-page',
  templateUrl: './events-page.component.html',
  styleUrls: ['./events-page.component.css']
})
export class EventsPageComponent implements OnInit {

  customOptions: OwlOptions = {
    center: true,
    loop: true,
    margin: 20,
    autoplay: true,
    autoplaySpeed: 4000,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    dotsEach:false,
    navSpeed: 1000,
    nav: true,
    navText:["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"],
    startPosition: 1,
    responsive: {
      0: {
        items: 1 
      },
      768: {
        items: 3
      }
    }
  }
  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("Events");
  }

}
