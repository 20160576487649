import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-easy-cablefinder-deal-page',
  templateUrl: './blog-easy-cablefinder-deal-page.component.html',
  styleUrls: ['./blog-easy-cablefinder-deal-page.component.css']
})
export class BlogEasyCablefinderDealPageComponent implements OnInit {

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("Here’s How Easy a CableFinder Deal Can Be");
  }

}
