import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser'

@Component({
  selector: 'app-blog-enjoy-your-summer-page',
  templateUrl: './blog-enjoy-your-summer-page.component.html',
  styleUrls: ['./blog-enjoy-your-summer-page.component.css']
})
export class BlogEnjoyYourSummerPageComponent implements OnInit {

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("With CableFinder, You Can Enjoy Your Summer. Users and Channel Leaders Tell You How");
  }

}
