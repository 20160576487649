<!-- Banner section starts -->

<div class="hero-banner-bg">
    <div class="container-fluid">
        <div class="row hero-banner-row">
            <div class="col-md-6 hero-banner-div">
                <h1 class="hero-banner-heading">Next Steps</h1>
                <p class="hero-banner-text">Thank you for expressing interest in working with CableFinder to improve how quickly you can access serviceability results.</p>
            </div>
        </div>
    </div>
</div>

<!-- Banner section ends -->

<!-- Form section starts -->

<div id="contact-form" class="container-fluid cf-form-section">
    <app-cf-register-form></app-cf-register-form>
</div>

<!-- Form section ends -->

<!-- Links & resources section starts -->

<div class="container-fluid link-n-resource-section">

    <div class="row link-n-resource-heading-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 link-n-resource-div">
            <p class="heading">Other Helpful Links & Resources <img class="image" src="../../../assets/images/get-started-page/Link Icon@2x.png" height="25" alt="LinksAndResources"></p>
            <p class="text">Browse other helpful links and resources that may help answer your questions below.</p>
        </div>
    </div>

    <div class="row link-n-resource-members-row">

        <div class="col-xs-11 col-sm-11 col-md-11 col-lg-5 card link-n-resource-card">
            <div class="row link-n-resource-card-row">
                <div class="col-3 link-n-resource-card-image">
                    <span class="link-n-resource-member-image"><i class="fa fa-long-arrow-right"></i></span>
                </div>
                <div class="col-9 link-n-resource-card-text">
                    <a [routerLink]="['/videos']" class="link-n-resource-card-text-heading">Browse Video Resources</a>
                    <p class="link-n-resource-card-sub-text">Checkout the CableFinder video resource library, where we have educational videos that help you understand how to use the tool.</p>
                </div>
            </div>
        </div>
        <div class="col-xs-11 col-sm-11 col-md-11 col-lg-5 card link-n-resource-card">
            <div class="row link-n-resource-card-row">
                <div class="col-3 link-n-resource-card-image">
                    <span class="link-n-resource-member-image"><i class="fa fa-long-arrow-right"></i></span>
                </div>
                <div class="col-9 link-n-resource-card-text">
                    <a [routerLink]="['/user-guide']" class="link-n-resource-card-text-heading">FAQ & User Guide</a>
                    <p class="link-n-resource-card-sub-text">Checkout the User-Guide page on our website to get all the answers to frequently asked questions, or to gain access of the CableFinder User Guide.</p>
                </div>
            </div>
        </div>
        <div class="col-xs-11 col-sm-11 col-md-11 col-lg-5 card link-n-resource-card">
            <div class="row link-n-resource-card-row">
                <div class="col-3 link-n-resource-card-image">
                    <span class="link-n-resource-member-image"><i class="fa fa-long-arrow-right"></i></span>
                </div>
                <div class="col-9 link-n-resource-card-text">
                    <a [routerLink]="['/news-and-updates']" class="link-n-resource-card-text-heading">Checkout News & Updates</a>
                    <p class="link-n-resource-card-sub-text">All the latest news & updates for CableFinder can be found on the News & Updates page of our website.</p>
                </div>
            </div>
        </div>
        <div class="col-xs-11 col-sm-11 col-md-11 col-lg-5 card link-n-resource-card">
            <div class="row link-n-resource-card-row">
                <div class="col-3 link-n-resource-card-image">
                    <span class="link-n-resource-member-image"><i class="fa fa-long-arrow-right"></i></span>
                </div>
                <div class="col-9 link-n-resource-card-text">
                    <a [routerLink]="['/events']" class="link-n-resource-card-text-heading">The Latest Events</a>
                    <p class="link-n-resource-card-sub-text">Stay up to date with the latest happening with CableFinder, over on our events page, and get registered for the next event!</p>
                </div>
            </div>
        </div>

    </div>

</div>

<!-- Links & resources section ends -->