import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-coffee-with-craig-and-kevin-page',
  templateUrl: './blog-coffee-with-craig-and-kevin-page.component.html',
  styleUrls: ['./blog-coffee-with-craig-and-kevin-page.component.css']
})
export class BlogCoffeeWithCraigAndKevinPageComponent implements OnInit {

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("Jed Kenzy on the “Coffee With Craig and Kevin” Podcast to Talk CableFinder");
  }

}
