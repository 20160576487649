<div class="row article-title-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-title-div">
        <p class="article-title">Welcome to the Team Jeff Pratt!</p>
    </div>
</div>

<div class="row article-post-date-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-post-date-div">
        <p class="article-post-date">April 28th,2021 <span class="author">by Nicole Heinz</span></p>
    </div>
</div>

<div class="row article-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">CableFinder is excited to announce the addition of Jeff Pratt to the team. Jeff will be CableFinder's Senior Director of Strategic Alliances, responsible for the strategy, account management, and business development of Master Agents, Call Centers, Digital Channels.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">Jeff has spent his entire career in the cable and telecommunications industry within sales channels. Most recently, he managed the authorized Partner Program at Spectrum Business supporting over 100 partners ranging from Energy Concierge companies, Master Dealers, Satellite Dealers, and Call Centers. Jeff's group was responsible for defining the strategy for these resellers, managing the day-to-day relationships, and working with divisional counterparts to execute the plan and grow sales volume.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">Jeff's background gives him a keen understanding of CableFinder from the partner's and the provider's perspective. Partners are faced with ongoing challenges and need to diversify their revenue streams. Providers are focused on growing quality volume seamlessly. CableFinder can service the needs for providers and partners alike, and Jeff can easily help connect those dots knowing both sides of the fence.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">In the first few months at CableFinder, Jeff is setting some big goals. He will be looking to increase the adoption and usage rates throughout our existing partners through bolstered account management and awareness. He's also looking to generate new business for the company by partnering with call center partners and make introductions to residential partners whose business leads are otherwise falling on the floor.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">CableFinder is fortunate to have found such a well-rounded and industry-savvy sales expert. When asked why Jeff chose to come work from our team, he said, "Many successful companies out there have streamlined the process to better serve their customers, and that's exactly what CableFinder has done to the cable and telecommunications industry. They have taken the cable sales process and streamlined it to where it just takes minutes from serviceability to signature. I'm very excited to be a part of the momentum that's building here and be a part of this team."</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">The feeling is mutual, Jeff. We're looking forward to seeing Jeff execute on his goals this year and for him to help propel us to the next level. </p>
    </div>

</div>