import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CfSalesforceService {

  private SALESFORCE_SUBMIT_FORM_URL: string = environment.SALESFORCE_SUBMIT_FORM_URL;

  constructor(private httpClient: HttpClient) { }


  public sendCFRegisterFormRequest(payload: any) : Observable<any> {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')

    return this.httpClient.post<any>(this.SALESFORCE_SUBMIT_FORM_URL, payload, { headers: headers })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      )    
  }
}
