<!-- Navbar section - Mobile starts -->
<div class="container-fluid">    
    <div class="row top-bar d-flex d-md-none">
        <nav class='navbar navbar-expand-md navbar-dark custom-navbar'>        
            <div class="col-xs-9 col-sm-9 cf-logo-div">
                <a [routerLink]="'/'"><img id="cf-logo-top-bar" src="../assets/images/2021 CableFinder Logo.png" alt="CableFinder logo" class="CF-logo img-responsive" /></a>
            </div>
            <div class="col-xs-3 col-sm-3 nav-menu-section">                
                <button id="navbar-toggle-button" onclick="toggleScroll()" class='navbar-toggler adjust-toggler' type='button' data-toggle='collapse' data-target='#navbarCollapse'>
                    <span id="fa-menu-icon" class='fa fa-bars fa-custom'></span>
                </button>                
            </div>            
            <div class="row mobile-menu-toggle-row">
                <div class="col-12 mobile-menu-toggle-div">
                    <div id='navbarCollapse' class='collapse navbar-collapse custom-navbar-collapse'>
                        <div class='navbar-nav custom-navbar-nav'>
                            <a class='nav-item nav-link' id='home-a' onclick="closeNavCollapseMenu()" [routerLink]="'/'">Home</a>
                            <a class='nav-item nav-link' id='about-a' onclick="closeNavCollapseMenu()" [routerLink]="'/about'">About</a>
                            <a class='nav-item nav-link' id='events-a' onclick="closeNavCollapseMenu()" [routerLink]="'/events'">Events</a>
                            <li class='nav-item dropdown custom-dropdown-div'>
                                <a class='nav-link dropdown-toggle' id='resources-a' href="#" data-toggle="dropdown">Resources</a>
                                <ul class="dropdown-menu custom-dropdown-menu">
                                    <li><a class="dropdown-item" onclick="closeNavCollapseMenu()" [routerLink]="'/news-and-updates'">News & Updates</a></li>
                                    <li><a class="dropdown-item" onclick="closeNavCollapseMenu()" [routerLink]="'/videos'">Videos</a></li>
                                    <li><a class="dropdown-item" onclick="closeNavCollapseMenu()" [routerLink]="'/user-guide'">User Guide</a></li>
                                </ul>
                            </li>
                            <a class='nav-item nav-link' id='get-started-a' onclick="closeNavCollapseMenu()" [routerLink]="'/get-started'">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
            
        </nav>
    </div>    
</div>
<!-- Navbar section - Mobile ends -->

<!-- Navbar section - Desktop starts -->
<div class="container-fluid">
    <div class="row top-bar d-none d-md-flex">
        <div class="col-md-4 col-lg-4 col-xl-5">
            <a [routerLink]="'/'"><img src="../assets/images/2021 CableFinder Logo.png" alt="CableFinder logo" class="CF-logo img-responsive" width="400" height="53" /></a>
        </div>
        <div class="col-md-8 col-lg-8 col-xl-7 nav-menu-section">
            <nav class='navbar navbar-expand-md navbar-light'>
                <button class='navbar-toggler adjust-toggler' type='button' data-toggle='collapse' data-target='#navbarCollapse'>
                <span class='navbar-toggler-icon'></span>
                </button>
                <div id='navbarCollapse' class='collapse navbar-collapse justify-content-between'>
                    <div class='navbar-nav'>
                        <a class='nav-item nav-link' id='home-a' [routerLink]="'/'">Home</a>
                        <a class='nav-item nav-link' id='about-a' [routerLink]="'/about'">About</a>
                        <a class='nav-item nav-link' id='events-a' [routerLink]="'/events'">Events</a>
                        <li class='nav-item dropdown custom-dropdown-div'>
                            <a class='nav-link dropdown-toggle' id='resources-a' href="#" data-toggle="dropdown">Resources</a>
                            <ul class="dropdown-menu custom-dropdown-menu">
                                <li><a class="dropdown-item" [routerLink]="'/news-and-updates'">News & Updates</a></li>
                                <li><a class="dropdown-item" [routerLink]="'/videos'">Videos</a></li>
                                <li><a class="dropdown-item" [routerLink]="'/user-guide'">User Guide</a></li>
                            </ul>
                        </li>
                        <a class='nav-item nav-link' id='get-started-a' [routerLink]="'/get-started'">Get Started</a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>

<!-- Navbar section - Desktop ends -->



<!-- Yellow bar section starts-->

<div class="container-fluid">
    <div class="row yellow-bar-row">
        <div class="col-12 yellow-bar-div">
            <p class="yellow-bar-text">The First of It's Kind! CableFinder Allows You to Contract, Qualify, and Quote in One Easy-to-Use Portal!</p>
        </div>    
    </div>
</div>


<!-- Yellow bar section starts-->
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

<!-- Page Content section starts -->

<!-- <app-index-page></app-index-page> -->
<router-outlet></router-outlet>



<!-- Page Content section starts -->

<!-- Footer section starts -->
    <div class="footer-section-bg">
        <div class="container-fluid">
            <div class="row primary-footer-row">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-7 primary-footer-videos-div">
                    <p class="primary-footer-heading">Check Out the Tool For Yourself...</p>
                    <div class="primary-footer-video-iframe-div">
                        <iframe src="https://www.youtube.com/embed/9CUBrKibIIg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div class="primary-footer-video-iframe-div">
                        <iframe src="https://www.youtube.com/embed/zgBgOf64ynw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div class="primary-footer-video-iframe-div">
                        <iframe src="https://www.youtube.com/embed/ajHA1jOHcfo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <p class="primary-footer-sub-heading">Interested In Enabling Your Organization With CableFinder?</p>
                    <div class="primary-footer-license-tool-div">
                        <a class="primary-footer-license-tool-link" href="/get-started#contact-form">Click Here to License the Tool</a>
                    </div>
                </div>
                    
                <div class="col-sm-12 col-md-3 col-lg-3 col-xl-2  primary-footer-access-div">
                    <p class="primary-footer-heading">Access Your Portal</p>
                    <table cellspacing="0" cellpadding="0" class="access-portal-table">
                        <tr>
                            <td><a href="https://appsmart.cablefinder.net">AppDirect</a></td>
                            <td><a href="https://intelisys.cablefinder.net">Intelisys</a></td>
            
                        </tr>
                        <tr>
                            <td><a href="https://avant.cablefinder.net">Avant</a></td>
                            <!-- <td><a href="https://tbi.cablefinder.net">TBI</a></td> -->
                            <td><a href="https://loc8.cablefinder.net">Pax8</a></td>
                        </tr>
                        <tr>
                            <td><a href="https://innovative.cablefinder.net">Innovative</a></td>
                            <td><a href="http://telarus.cablefinder.net/">Telarus</a></td>
                        </tr>
                    </table>
                </div>
    
                <div class="col-sm-12 col-md-3 primary-footer-updates-div">
                    <p class="primary-footer-heading">Recent Updates</p>
                    <div class="primary-footer-updates-div">
                        <a class="primary-footer-updates-heading" [routerLink]="'/news-and-updates/welcome-to-the-team-jeff-prat'">Welcome to the Team Jeff Pratt!</a>
                        <p class="primary-footer-updates-posted">April 28th, 2021</p>
                    </div>
                    <div class="primary-footer-updates-div">
                        <a class="primary-footer-updates-heading" [routerLink]="'/news-and-updates/intelisys-channel-connect'">Intelisys Channel Connect: ‘Partner Reviews,’ Yelp for the Channel, Debuts</a>
                        <p class="primary-footer-updates-posted">October 14th, 2020</p>
                    </div>
                    <div class="primary-footer-updates-div">
                        <a class="primary-footer-updates-heading" [routerLink]="'/news-and-updates/deal-or-no-deal'">CableFinder: The Difference Between a Deal, or No Deal</a>
                        <p class="primary-footer-updates-posted">July 9th, 2020</p>
                    </div>
                </div>
            </div>
    
            <div class="row primary-footer-cf-logo-row">
                <div class="col-12 primary-footer-cf-logo-div">
                    <img class="primary-footer-cf-logo" src="../assets/images/2021CableFinderLogoWhite.png" alt="CableFinder Logo" />
                    <p class="primary-footer-cf-logo-text">"From Serviceability to Signature in Sixty Seconds."</p>
                </div>
            </div>
        </div>
    </div>
        
    <div class="secondary-footer-bg">
        <div class="container-fluid">
            <div class="row secondary-footer-row">
                <div class="col-sm-12 col-md-4 connect-with-us-div">
                    <span class="connect-with-us-text">Connect With Us</span>
                    <a href="https://www.linkedin.com/company/cablefinder" class="social-icons"><img class="social-icon-img" src="../assets/images/linkedin-icon.png" alt="LinkedIn" /></a>
                    <a href="mailto:Info@CableFinder.net" class="social-icons"><img class="social-icon-img" src="../assets/images/email-icon.png" alt="Email" /></a>
                </div>
                <div class="col-sm-12 col-md-4 rights-div">
                    <p class="rights-text">© {{rightsYear}} CableFinder, All Rights Reserved.</p>
                </div>
                <div class="col-sm-12 col-md-4 footer-links-div">
                    <a class="footer-links" [routerLink]="'/user-guide'">FAQs</a>
                    <a class="footer-links" [routerLink]="'/get-started'">Get Started</a>
                    <a class="footer-links" [routerLink]="'/next-steps'">Next Steps</a>
                </div>
            </div>
        </div>
    </div>  

<!-- Footer section ends-->

