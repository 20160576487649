<div class="row article-title-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-title-div">
        <p class="article-title">Introducing a New Service: Cable Finder</p>
    </div>
</div>

<div class="row article-post-date-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-post-date-div">
        <p class="article-post-date">June 25,2019 <span class="author">by Shane Fugazy</span></p>
    </div>
</div>

<div class="row article-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">If you’re looking to meet your customers’ every need and help them find the right cable provider, Cable Finder is the industry’s latest, unique solution. Cable Finder is a cable serviceability tool that not only provides unmatched accuracy for serviceability but also simplifies qualifying, quoting, and contracting of cable services. From multi-site search to cable and coax serviceability and more, Cable Finder has got what you need to simplify the search for the best cable provider for your customers.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-sub-heading heading-blue">What Makes Cable Finder Different</p>
        <p class="article-text">Cable Finder is a tool used to help identify cable companies and their coax or fiber serviceability at any given address for the top four cable companies in the US. It’s a cable-only serviceability and pricing tool that includes the key differentiators below.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text"><span class="article-sub-heading">Updated data on most major cable companies:</span> Different from a static database, with Cable Finder you get a dynamic platform that provides the most updated information on a customer’s location’s serviceability by directly logging into the top two cable companies in the US. It connects to the cable companies’ own tools using an API, or it uses iFrame and logs into their tools. It then loads the information into the proprietary password protected tool and pulls that information directly back into Cable Finder. This ensures what you’re getting is the same information you’d get from a direct employee working at the cable company of interest – through their proprietary systems.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text"><span class="article-sub-heading">Integrated with Google for address sourcing:</span> We’ve integrated Cable Finder with Google so partners can now search addresses by customer name – just like they would in a regular Google search or on Google Maps – instead of needing to know the exact customer address. Partners can search by individual name or business name and see the address pre-populate.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text"><span class="article-sub-heading">All in the same tool:</span> Cable Finder does cable or coax serviceability, cable broadband serviceability, and fiber optic serviceability – all in one tool. With fiber, Cable Finder gives you an estimate of what that construction is going to look like specifically for Comcast.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text"><span class="article-sub-heading">Places app:</span> We’ve integrated the Places app into Cable Finder so every address that’s returned from a search will have Places integration. As a result, the business names and contact information of the other tenants in that building will also be on display. This can be an advantage for partners because it gives you prospecting information to solicit other tenants to fund a fiber or coax build – especially if it’s too expensive for one customer to cover.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text"><span class="article-sub-heading">Pricing configuration and quote generation:</span> With Cable Finder, you get an Amazon-cart-style shopping experience. This solution allows for pricing configuration and quote generation in the tool itself so you have the option to choose coax pricing as well as major cable company products. Once you’ve selected what you want, Cable Finder will then apply the most current discounts available to the quote and proceed to generate a quote with custom user information – producing a PDF in a matter of seconds.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">This can all be done with zero email communication, saving you time and encouraging more sales.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text"><span class="article-sub-heading">Multi-site look-up tool:</span> Pricing out cable across multiple states, territories, and cable companies is frustrating, and that growing struggle has led to massive market opportunity. Partners rely on the master to know where and which cable companies are available. With Cable Finder, there’s no need to utilize an aggregator. Instead of waiting weeks to get results back from a company, this tool can give partners an initial high-level overview in a matter of minutes for both coax and fiber.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">What’s more, you can upload a simple CSV file in multiple different formats. There’s no need to spend hours formatting your spreadsheet exactly how Spectrum, Cox, or other cable companies want it.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text"><span class="article-sub-heading">Live chat and other integrations:</span> Say goodbye to emails. Cable Finder has an integrated live chat with live agents who are standing by and are ready to help. Cable Finder also has integrated services such as manual quote requests. So if there’s interest in additional carriers outside of what’s loaded in Cable Finder, the option of engaging the Innovative team is available through the tool. Additionally, Cable Fiber is able to email quotes to a multitude of email addresses directly out of the tool, saving you the long process of sending it to a customer.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-sub-heading heading-blue">Ready to Integrate Cable Finder in Your Business?</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">If you want in on the industry’s best cable serviceability tool, you can count on Innovative to deliver. With Cable Finder, you’ll eliminate the challenges found in identifying the cable company and coax or fiber serviceability that is right your customer.</p>
    </div>

</div>