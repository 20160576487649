import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'app-index-page',
  templateUrl: './index-page.component.html',
  styleUrls: ['./index-page.component.css']
})
export class IndexPageComponent implements OnInit {

  isSubmitted=false;

  /* Master Agents List */
  MasterAgents: any = ['AppDirect', 'Avant', 'Innovative Business Solutions', 'Intelisys', 'Pax 8', 'Telarus'];

  constructor(private titleService: Title, public fb: FormBuilder) { }

  ngOnInit(): void {
    this.titleService.setTitle("CableFinder");
  }

   /* Master Agent Select  */

   public masterAgentForm = this.fb.group({
    masterAgents: ['', Validators.required]
  });

  getAgent(){
    return this.masterAgentForm.get('masterAgents');
  }

  onSubmit(){
   // alert(JSON.stringify(this.masterAgentForm.controls['masterAgents'].value));

   let agent = this.masterAgentForm.controls['masterAgents'].value;  
   if(agent == "Intelisys"){
      agent = "intelisys";
   }
   else if(agent == "Innovative Business Solutions"){
     agent = "innovative";
   }
   else if(agent == "Pax 8"){
    agent = "loc8";
   }
  //  else if(agent == "AppSmart"){
  //   agent = "appsmart";
  //  }
   else if(agent == "AppDirect"){
    agent = "appdirect";
   }
   else if(agent == "Avant"){
    agent = "avant";
    }
  //  else if (agent == "TBI") {
  //    agent = "tbi";
  //  }
   window.location.href='http://' + agent + '.cablefinder.net';  
  }
}
