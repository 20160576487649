<!-- Banner section starts -->

<div class="hero-banner-bg">
    <div class="container-fluid">
        <div class="row hero-banner-row">
            <div class="col-md-6 hero-banner-div">
                <h1 class="hero-banner-heading">Upcoming Events For CableFinder</h1>
                <p class="hero-banner-text">See all the latest for CableFinder upcoming events and webinars below.</p>
            </div>
        </div>
    </div>
</div>

<!-- Banner section ends -->

<!-- Event section starts -->

<div class="container-fluid">
    <div class="row cf-events-heading-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 cf-events-heading-div">
            <!-- <h3 class="cf-events-heading-text">Up Next: Check Back Later</h3> -->
            <!-- <p class="cf-events-subheading-text">Register for the upcoming webinar through the details listed below.</p> -->
        </div>
    </div>

    <div class="row cf-event-row">
        <div class="col-xs-12 col-sm-12 col-md-12 cf-event-title-div">
            <p class="cf-event-title-heading mt-4">Stay Tuned for More Exciting Updates from CableFinder!</p>
        </div>
    </div>

    <!-- <div class="row cf-event-row">
        <div class="col-xs-12 col-sm-12 col-md-12 cf-event-title-div">
            <p class="cf-event-title-heading">Where Is</p>
            <img class="cf-event-logo" src="../../../assets/images/2021 CableFinder Logo.png" alt="CableFinder logo"/><label class="cf-event-title-questionmark">?</label>
        </div>
    </div> -->

    <!-- <div class="row cf-event-row cf-summer-contest-row">
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-3 cf-event-img-div">
            <img class="cf-event-img" src="../../../assets/images/event-page/cablefinder-imagery.png" alt="CableFinder Imagery" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-6 cf-event-desc-div">
            <p class="cf-event-desc-heading">2023 Summer Contest</p>
            <p class="cf-event-desc-text">We are launching a fun summer contest that will run June 1st through September 4, 2023. From simply posting a picture, you'll have the opportunity to win prizes! CableFinder reserves the right to modify the contest terms at any time.</p>
            <p class="cf-event-desc-heading">How to Participate</p>
            <p class="cf-event-desc-text">While you head out on vacation this summer, be sure to pack your CableFinder YETI! Take a picture showing your YETI and post it to LinkedIn with the hashtag #WhereIsCableFinder. Each picture you take will qualify for an entry - the more pictures you take, the more chances you have to win! You'll have two chances to win with prizes being awarded to one randomly selected person and one prize to the person with the most entries/reshares.</p>
        </div>
    </div> -->

    <!-- <div class="row cf-event-row cf-summer-contest-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-9 cf-event-title-div">
            <p class="cf-event-title-heading">Winner's Prize!</p>
            <p class="cf-event-title-text">One randomly selected winner will receive a HOPPER FLIP® 8 SOFT COOLER and the person with the most entries/reshares will receive a HOPPER FLIP® 8 SOFT COOLER.</p>
        </div>
    </div> -->

    <!-- <div class="row cf-event-row cf-summer-contest-row">
        <div class="col-xs-12 col-sm-12 col-md-3 cf-event-prize-div"> -->
            <!-- <div class="cf-event-prize-img-div">
                <img class="cf-event-prize-img" src="../../../assets/images/event-page/hopper-flip8-soft-cooler.png" alt="Hopper Flip8 Soft Cooler" />
            </div>            
            <p class="cf-event-prize-text">$500 Gift Card</p> -->
        <!-- </div> -->
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 cf-event-prize-div">
            <div class="cf-event-prize-img-div">
                <img class="cf-event-prize-img" src="../../../assets/images/event-page/hopper-flip8-soft-cooler.png" alt="Hopper Flip8 Soft Cooler" />
            </div>            
            <p class="cf-event-prize-text">HOPPER FLIP® 8 SOFT COOLER</p>
        </div> -->
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 cf-event-prize-div"> -->
            <!-- <div class="cf-event-prize-img-div">
                <img class="cf-event-prize-img" src="../../../assets/images/event-page/soft-cooler.png" alt="YETI Soft Cooler" />
            </div>
            <p class="cf-event-prize-text">YETI Soft Cooler</p> -->
        <!-- </div>
    </div> -->

    <!-- <div class="row cf-event-row">
        <div class="col-12 cf-event-title-div">
            <p class="cf-event-sub-text">We're looking forward to seeing where you travel this summer,<br/>taking CableFinder wherever you are!</p>
        </div>
    </div> -->


</div>

<!-- Event section ends -->

<!-- Form section starts-->

<div class="form-section-bg">
    <div class="container-fluid">
        <div class="row form-heading-row">
            <div class="col-12 form-heading-div">
                <h3 class="form-heading-text">Want to Host an Event with CableFinder?</h3>
                <p class="form-sub-heading-text">Whether your organization has just adopted the tool, or if you're
                    looking to set up a demo, use the form below to get in touch.</p>
            </div>
        </div>
        <div class="row form-row">
            <div class="col-12 form-div">
                <app-cf-register-demo-day-form></app-cf-register-demo-day-form>
            </div>
        </div>
    </div>
</div>

<!-- Form section ends -->

<!-- Tutorial section starts -->

<div class="container-fluid">
    <div class="row tutorials-heading-row">
        <div class="col-12 tutorials-heading-div">
            <h3 class="col-12 tutorials-heading-text">Can't Wait For An Event? Click Below For Tutorials</h3>
        </div>
    </div>
    <div class="row tutorials-row">
        <div class="col-12 tutorials-div">
            <owl-carousel-o [options]="customOptions" class="tutorials-slider">
                <ng-template carouselSlide>
                    <div class="slide tutorials-slide-div">
                        <iframe width="260" height="200" src="https://www.youtube.com/embed/3eeAZhincgI"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="slide tutorials-slide-div">
                        <iframe width="260" height="200" src="https://www.youtube.com/embed/Q-JiQIpkRHA"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="slide tutorials-slide-div">
                        <iframe width="260" height="200" src="https://www.youtube.com/embed/mwHxD6Hhixs"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="slide tutorials-slide-div">
                        <iframe width="260" height="200" src="https://www.youtube.com/embed/PODKRF_03HY"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="slide tutorials-slide-div">
                        <iframe width="260" height="200" src="https://www.youtube.com/embed/zgBgOf64ynw"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="slide tutorials-slide-div">
                        <iframe width="260" height="200" src="https://www.youtube.com/embed/W1-KvXO_qtg"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="slide tutorials-slide-div">
                        <iframe width="260" height="200" src="https://www.youtube.com/embed/jI5477LTZgU"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="slide tutorials-slide-div">
                        <iframe width="260" height="200" src="https://www.youtube.com/embed/6rEDGa3HL78"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="slide tutorials-slide-div">
                        <iframe width="260" height="200" src="https://www.youtube.com/embed/HiNnjmBftRk"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>




<!-- Tutorial section ends -->