import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexPageComponent } from './pages/index-page/index-page.component';
import { EventsPageComponent } from './pages/events-page/events-page.component';
import { ResourcesPageComponent } from './pages/resources-page/resources-page.component';
import { GetStartedPageComponent } from './pages/get-started-page/get-started-page.component';
import { CFRegisterFormComponent } from './resources/cf-register-form/cf-register-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewsAndUpdatesPageComponent } from './pages/news-and-updates-page/news-and-updates-page.component';
import { CfVideosPageComponent } from './pages/cf-videos-page/cf-videos-page.component';
import { UserGuidePageComponent } from './pages/user-guide-page/user-guide-page.component';
import { BlogDealOrNoDealPageComponent } from './pages/blog-deal-or-no-deal-page/blog-deal-or-no-deal-page.component';
import { BlogEnjoyYourSummerPageComponent } from './pages/blog-enjoy-your-summer-page/blog-enjoy-your-summer-page.component';
import { BlogEasyCablefinderDealPageComponent } from './pages/blog-easy-cablefinder-deal-page/blog-easy-cablefinder-deal-page.component';
import { BlogCoffeeWithCraigAndKevinPageComponent } from './pages/blog-coffee-with-craig-and-kevin-page/blog-coffee-with-craig-and-kevin-page.component';
import { BlogIbsIntroducesCablefinderToolPageComponent } from './pages/blog-ibs-introduces-cablefinder-tool-page/blog-ibs-introduces-cablefinder-tool-page.component';
import { BlogNewServiceCablefinderPageComponent } from './pages/blog-new-service-cablefinder-page/blog-new-service-cablefinder-page.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { BlogIntelisysChannelConnectPageComponent } from './pages/blog-intelisys-channel-connect-page/blog-intelisys-channel-connect-page.component';
import { PrivacyPolicyPageComponent } from './pages/privacy-policy-page/privacy-policy-page.component';
import { CfRegisterDemoDayFormComponent } from './resources/cf-register-demo-day-form/cf-register-demo-day-form.component';
import { BlogWelcomeJeffPrattComponent } from './pages/blog-welcome-jeff-pratt/blog-welcome-jeff-pratt.component';
import { CfTestimonialCarouselComponent } from './resources/cf-testimonial-carousel/cf-testimonial-carousel.component';
import { NextStepsPageComponent } from './pages/next-steps-page/next-steps-page.component';
import { UserGuidesPageComponent } from './pages/user-guides-page/user-guides-page.component';
import { ToastsContainer } from './resources/components/toasts-container.component';
import { BlogCfAttPartnershipComponent } from './pages/blog-cf-att-partnership/blog-cf-att-partnership.component';
import { IntroducingZayoPricingAndContractGenerationComponent } from './pages/introducing-zayo-pricing-and-contract-generation/introducing-zayo-pricing-and-contract-generation.component';
import { BlogCFandSpectrumBusinessPressReleaseComponent } from './pages/blog-press-release-for-cablefinder-website/blog-cf-and-spectrum-business-press-release.component';




@NgModule({
  declarations: [
    AppComponent,
    IndexPageComponent,
    EventsPageComponent,    
    ResourcesPageComponent,
    GetStartedPageComponent,
    CFRegisterFormComponent,
    NewsAndUpdatesPageComponent,
    CfVideosPageComponent,
    UserGuidePageComponent,
    BlogDealOrNoDealPageComponent,
    BlogEnjoyYourSummerPageComponent,
    BlogEasyCablefinderDealPageComponent,
    BlogCoffeeWithCraigAndKevinPageComponent,
    BlogIbsIntroducesCablefinderToolPageComponent,
    BlogNewServiceCablefinderPageComponent,
    AboutPageComponent,
    BlogIntelisysChannelConnectPageComponent,
    PrivacyPolicyPageComponent,
    CfRegisterDemoDayFormComponent,
    BlogWelcomeJeffPrattComponent,
    CfTestimonialCarouselComponent,
    NextStepsPageComponent,
    UserGuidesPageComponent,
    ToastsContainer,
    BlogCfAttPartnershipComponent,
    IntroducingZayoPricingAndContractGenerationComponent,
    BlogCFandSpectrumBusinessPressReleaseComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    BrowserAnimationsModule,
    CarouselModule
  ],
  providers: [
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
