import { Component, OnInit } from '@angular/core';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-cf-testimonial-carousel',
  templateUrl: './cf-testimonial-carousel.component.html',
  styleUrls: ['./cf-testimonial-carousel.component.css']
})
export class CfTestimonialCarouselComponent {

  customOptions: OwlOptions = {
    center: true,
    loop: true,
    margin: 20,
    autoplay: true,
    autoplaySpeed: 1000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    nav: false,
    navSpeed: 1000,
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 1
      },
      769: {
        items: 3
      },
      1024: {
        items: 3
      },
      1440: {
        items: 3
      }
    }
  }

}
