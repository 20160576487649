import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CfHubSpotService {

  private hubspot_cf_register_endpoint = "https://api.hsforms.com/submissions/v3/integration/submit/9039554/6f6cdb71-abd6-4627-ad3f-e9ff6961abed";
  private hubspot_cf_subscribe_newsletter_endpoint = "https://api.hsforms.com/submissions/v3/integration/submit/9039554/9ecb83f4-a50b-4317-9c73-3cc7c203bef1";
  private hubspot_cf_get_started_endpoint = "https://api.hsforms.com/submissions/v3/integration/submit/9039554/03c42106-35be-43be-943b-efbefb788444";
  private hubspot_cf_user_guide_endpoint = "https://api.hsforms.com/submissions/v3/integration/submit/9039554/d2cad85f-a5eb-4063-9169-d32ad988e6d6";
  private hubspot_cf_about_endpoint = "https://api.hsforms.com/submissions/v3/integration/submit/9039554/4479ef2e-79d0-4c8e-a7e7-31aed58d1e86";
  private hubspot_cf_next_steps_endpoint = "https://api.hsforms.com/submissions/v3/integration/submit/9039554/2705fa4c-e580-4066-9fcd-422930c8e143";
  private hubspot_cf_demo_day_endpoint = "https://api.hsforms.com/submissions/v3/integration/submit/9039554/dbee9a51-609b-4a92-933b-5ffa6ec2d20e";
  private hubspot_cf_submit_question_endpoint = "https://api.hsforms.com/submissions/v3/integration/submit/9039554/97094bcb-61c7-4fb0-a9c8-b492549df915";

  constructor(private httpClient: HttpClient) { }


  // Home Page CF Register form starts

  public sendCFRegisterFormRequest(data: any, type: string) : Observable<any> {
    
    let endpoint = '';
    if(type == 'index'){
      endpoint = this.hubspot_cf_register_endpoint;
      //alert(endpoint);
    }
    else if(type == 'get-started'){
      endpoint = this.hubspot_cf_get_started_endpoint;   
      //alert(endpoint);   
    }
    else if(type == 'about'){
      endpoint = this.hubspot_cf_about_endpoint;   
      //alert(endpoint);   
    }
    else if(type == 'next-steps'){     
      endpoint = this.hubspot_cf_next_steps_endpoint;   
      //alert(endpoint);   
    }
    return this.httpClient.post<any>(endpoint, data)
      .pipe(
        catchError((err) => {
          console.log('Error in service: ');
          console.log(err);
          return throwError(err);
        })
      )    
  }

  // Home Page CF Register form ends


  //CF Updates Subcribe form starts

  public sendCFNewsletterSubscribeRequest(data: any) : Observable<any> {
    //console.log("Form Data: " + data );
    return this.httpClient.post<any>(this.hubspot_cf_subscribe_newsletter_endpoint, data)
    .pipe(
      catchError((err) => {
        console.log('Error in service: ');
        console.log(err);
        return throwError(err);
      })
    )
  }

  //CF Updates Subcribe form ends



  //CF User Guide form starts

  public sendCFUserGuideRequest(data: any) : Observable<any> {
    //console.log("Form Data: " + data );
    return this.httpClient.post<any>(this.hubspot_cf_user_guide_endpoint, data)
    .pipe(
      catchError((err) => {
        console.log('Error in service: ');
        console.log(err);
        return throwError(err);
      })
    )
  }

  //CF User Guide form ends

  //CF Submit User Question form starts

  public sendCFUserQuestion(data: any) : Observable<any> {
    //console.log("Form Data: " + data );
    return this.httpClient.post<any>(this.hubspot_cf_submit_question_endpoint, data)
    .pipe(
      catchError((err) => {
        console.log('Error in service: ');
        console.log(err);
        return throwError(err);
      })
    )
  }

  //CF Submit User Question form ends


  //CF Demo  Day Register form starts

  public sendCFDemoDayRegisterRequest(data: any) : Observable<any> {
    //console.log("Form Data: " + data );
    return this.httpClient.post<any>(this.hubspot_cf_demo_day_endpoint, data)
    .pipe(
      catchError((err) => {
        console.log('Error in service: ');
        console.log(err);
        return throwError(err);
      })
    )
  }

  //CF Demo  Day Register form starts


}
