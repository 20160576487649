<form id='formCableFinderDemoDayRegister' class='formCableFinderDemoDayRegister' [formGroup]="cablefinderDemoDayRegisterForm" (ngSubmit)="submitDemoDayRegister()">
    <div class="container-fluid">
        <div class="row form-first-row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-field">
                <input type="text" formControlName="cfdemoregisterfirstname" class="cf-register-demo-day-firstname" id="cf-register-demo-day-firstname" placeholder="First Name" [ngClass]="{ 'is-invalid': isSubmitted && d.cfdemoregisterfirstname.errors }" />
                <div *ngIf="isSubmitted && d.cfdemoregisterfirstname.errors" class="invalid-feedback">
                    <div *ngIf="d.cfdemoregisterfirstname.errors.required">First Name is required</div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-field">
                <input type="text" formControlName="cfdemoregisterlastname" class="cf-register-demo-day-lastname" id="cf-register-demo-day-lastname" placeholder="Last Name" [ngClass]="{ 'is-invalid': isSubmitted && d.cfdemoregisterlastname.errors }" />
                <div *ngIf="isSubmitted && d.cfdemoregisterlastname.errors" class="invalid-feedback">
                    <div *ngIf="d.cfdemoregisterlastname.errors.required">Last Name is required</div>
                </div>
            </div>
        </div>
    
        <div class="row form-second-row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-field">
                <input type="email" formControlName="cfdemoregisteremail" class="cf-register-demo-day-email" id="cf-register-demo-day-email" placeholder="Email" [ngClass]="{ 'is-invalid': isSubmitted && d.cfdemoregisteremail.errors }" />
                <div *ngIf="isSubmitted && d.cfdemoregisteremail.errors" class="invalid-feedback">
                    <div *ngIf="d.cfdemoregisteremail.errors.required">Email is required</div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-field">
                <input type="text" formControlName="cfdemoregistercompany" class="cf-register-demo-day-businessname" id="cf-register-demo-day-businessname" placeholder="Company Name" [ngClass]="{ 'is-invalid': isSubmitted && d.cfdemoregistercompany.errors }" />
                <div *ngIf="isSubmitted && d.cfdemoregistercompany.errors" class="invalid-feedback">
                    <div *ngIf="d.cfdemoregistercompany.errors.required">Company Name is required</div>
                </div>
            </div>
        </div>
    
        <div class="row form-third-row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-field">
                <select formControlName="cfdemoregistermasteragent" class="cf-demo-day-register-masteragent" id="cf-demo-day-register-masteragent" [ngClass]="{ 'is-invalid': isSubmitted && d.cfdemoregistermasteragent.errors }" >
                    <option value="">What Distributor Referred You?</option>
                    <option *ngFor="let masteragent of MasterAgents">{{ masteragent }}</option>
                </select>
                <div *ngIf="isSubmitted && d.cfdemoregistermasteragent.errors" class="invalid-feedback">
                    <div *ngIf="d.cfdemoregistermasteragent.errors.required">Telecom Services Distributor is required</div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-field">
                <input type="text" formControlName="cfdemoregisterphone" class="cf-register-demo-day-phone" id="cf-register-demo-day-phone" placeholder="Phone Number" [ngClass]="{ 'is-invalid': isSubmitted && d.cfdemoregisterphone.errors }" />
                <div *ngIf="isSubmitted && d.cfdemoregisterphone.errors" class="invalid-feedback">
                    <div *ngIf="d.cfdemoregisterphone.errors.required">Phone is required</div>
                </div>
            </div>
        </div>
    
        <div class="row form-fourth-row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 cfdemodayregisterbtnsubmit-div">
                <button type='submit' id='cfdemodayregisterbtnsubmit' class='cfdemodayregisterbtnsubmit'>Click Here to Submit</button>
            </div>
        </div>
        

    </div>
    

</form>