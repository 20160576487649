<!-- An innovative tool section starts-->

<div class="container-fluid an-innovative-tool-section">

    <div class="row image-n-title-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 image-n-title-div">
            <div class="image-n-title-container">
                <img class="image-n-title-img img-responsive" src="../../../assets/images/2021CableFinderLogoWhite.png" alt="CableFinder tool" />
                <p class="image-n-title-text">An Innovative Tool That Makes Selling Cable Easy.</p>
            </div>
        </div>
    </div>

    <div class="row master-agent-row">

        <div class="row master-agent-heading-row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 master-agent-heading-div">
                <p class="master-agent-heading">Which Telecom Services Distributor Referred You to CableFinder?</p>
            </div>
        </div>

        <div class="row master-agent-form-row">
            <div class="master-agent-form-div">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 master-agent-form">
                        <form [formGroup]="masterAgentForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                                    <select formControlName="masterAgents">
                                    <option value="">Pick Your Distributor</option>
                                    <option *ngFor="let masteragent of MasterAgents">{{ masteragent }}</option>
                                    </select>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                    <button class="formmasteragentbtnsubmit">Get Started</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <p class="master-agent-form-sub-heading">Using the dropdown menu below, select your distributor, to access your portal.</p>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="row save-office-time-row">

        <div class="row save-office-time-heading-row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 save-office-time-div">
                <p class="heading">Save Your Back Office Time.</p>
            </div>
        </div>

        <div class="row save-office-time-members-row">

            <div class="col-xs-11 col-sm-11 col-md-11 col-lg-5 card save-office-time-card">
                <div class="row save-office-time-card-row">
                    <div class="col-3 save-office-time-card-image">
                        <img class="save-office-time-member-image img-responsive" src="../../../assets/images/feature-and-benefit/50x50EasytoUsePortal.png" alt="Jed-Kenzy" />
                    </div>
                    <div class="col-9 save-office-time-card-text">
                        <p class="save-office-time-card-text-heading">Easy-to-use Portal</p>
                        <p class="save-office-time-card-sub-text">Collapse multiple systems and portals into one easy-to-use tool.</p>
                    </div>
                </div>
            </div>
            <div class="col-xs-11 col-sm-11 col-md-11 col-lg-5 card save-office-time-card">
                <div class="row save-office-time-card-row">
                    <div class="col-3 save-office-time-card-image">
                        <img class="save-office-time-member-image img-responsive" src="../../../assets/images/feature-and-benefit/50x50APIDriven.png" alt="Mike-Trede" />
                    </div>
                    <div class="col-9 save-office-time-card-text">
                        <p class="save-office-time-card-text-heading">API Driven for Accuracy</p>
                        <p class="save-office-time-card-sub-text">API driven information, direct from the carriers, as accurate as they are!</p>
                    </div>
                </div>
            </div>
            <div class="col-xs-11 col-sm-11 col-md-11 col-lg-5 card save-office-time-card">
                <div class="row save-office-time-card-row">
                    <div class="col-3 save-office-time-card-image">
                        <img class="save-office-time-member-image img-responsive" src="../../../assets/images/feature-and-benefit/50x50ContractGeneration.png" alt="Eugene-Atha" />
                    </div>
                    <div class="col-9 save-office-time-card-text">
                        <p class="save-office-time-card-text-heading">Contract Generation</p>
                        <p class="save-office-time-card-sub-text">Immediate contract generation with built in signature capability.</p>
                    </div>
                </div>
            </div>
            <div class="col-xs-11 col-sm-11 col-md-11 col-lg-5 card save-office-time-card">
                <div class="row save-office-time-card-row">
                    <div class="col-3 save-office-time-card-image">
                        <img class="save-office-time-member-image img-responsive" src="../../../assets/images/feature-and-benefit/50x50CoaxandFiberResults.png" alt="Jeff-Pratt" />
                    </div>
                    <div class="col-9 save-office-time-card-text">
                        <p class="save-office-time-card-text-heading">Coax and Fiber Results!</p>
                        <p class="save-office-time-card-sub-text">Serviceability results for hundreds of addresses in minutes for both Coax and Fiber.</p>
                    </div>
                </div>
            </div>

        </div>

    </div>

</div>

<!-- An innovative tool section ends-->

<!-- What other say video section starts -->

<div class="container-fluid what-other-say-section">

    <div class="row what-other-say-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <p class="heading">Hear What Others Have to Say!</p>
            <p class="text">Hear what the channel has to say about the launch of CableFinder, the tool built to simplify the qualifying, quoting, and contracting of cable services.</p>
            <iframe class="video" width="560" height="315" src="https://youtube.com/embed/y93zNk0tD_I" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    </div>

</div>

<!-- What other say video section ends -->

<!-- Blue background section starts-->

<div class="container-fluid blue-background-section">

    <!-- Adapting The Tool section starts -->

    <div class="adapting-the-tool-section">

        <div class="row adapting-the-tool-row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <p class="heading">We Make Adopting the Tool Easy!</p>
            </div>
        </div>

        <div class="row text-cards-row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 adapting-the-tool-text-card">
                <span><i class="fa fa-check-circle tick-icon"></i></span>
                <p class="title">Dedicated on-boarding team to help you along the way.</p>
                <p class="text">CableFinder provides you with dedicated resources from our team, to ensure you get up and running to speed using CableFinder in your organization.</p>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 adapting-the-tool-text-card">
                <span><i class="fa fa-check-circle tick-icon"></i></span>
                <p class="title">Marketing materials made available to you!</p>
                <p class="text">Our marketing team is here to support your organization. Let’s brainstorm how to get user adoption up, and more of your organization using the tool!</p>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 adapting-the-tool-text-card">
                <span><i class="fa fa-check-circle tick-icon"></i></span>
                <p class="title">Brand the tool your way!</p>
                <p class="text">Our team makes it super easy to recolor, and add your own name to the application so that CableFinder fits into your own organization.</p>
            </div>
        </div>

    </div>

    <!-- Adapting The Tool section ends -->

    <!-- Registration form section starts -->

    <div class="row cf-form-section">
        <app-cf-register-form></app-cf-register-form>
    </div>

    <!-- Registration form section ends -->
</div>

<!-- Blue background section ends-->

<!-- Industry leaders section starts -->

<div class="container-fluid industry-leaders-section">

    <div class="industry-leaders-row">

        <div class="row industry-leaders-heading-row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 industry-leaders-div">
                <p class="heading">Trusted By Industry Leaders</p>
            </div>
        </div>

        <div class="industry-leaders-testimonial-row">
            <app-cf-testimonial-carousel></app-cf-testimonial-carousel>
        </div>

    </div>

    <div class="industry-leaders-logos-row">

      <div class="row justify-content-around">
        <div class="logo-1">
          <a href="https://appdirect.cablefinder.net"><img width="140px" height="auto" class="agent-logo app-direct" src="../../../assets/images/logos/appdirect-jogo.png" alt="AppDirect logo" /></a>
        </div>
        <div class="logo-2">
          <a href="https://avant.cablefinder.net"><img class="agent-logo" src="../../../assets/images/logos/avant-logo.png" alt="Avant logo" /></a>
        </div>
        <div class="logo-3">
          <a href="https://innovative.cablefinder.net"><img class="agent-logo" src="../../../assets/images/logos/innovative-logo-agent.png" alt="Innovative Business Solutions logo" /></a>
        </div>
        <div class="logo-4">
          <a href="https://intelisys.cablefinder.net"><img class="agent-logo" src="../../../assets/images/logos/intelisys-logo.png" alt="Intelisys logo" /></a>
        </div>
        <div class="logo-5">
            <a href="https://intelisys.cablefinder.net"><img class="agent-logo" src="../../../assets/images/logos/pax8-logo.png" alt="Pax8 logo" /></a>
        </div>
        <div class="logo-6">
            <a href="https://telarus.cablefinder.net"><img width="140px" height="auto" class="agent-logo" src="../../../assets/images/logos/telatus-logo.png" alt="telatus logo" /></a>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 logo-1"></div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 logo-2">
          <a href="https://loc8.cablefinder.net"><img class="agent-logo" src="../../../assets/images/logos/pax8-logo.png" alt="Pax8 logo" /></a>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 logo-3">
          <a href="https://tbi.cablefinder.net"><img class="agent-logo" src="../../../assets/images/logos/tbi-logo.png" alt="TBI logo" /></a>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 logo-4"></div>
      </div> -->

    </div>

</div>

<!-- Industry leaders section ends-->

<!-- Carriers Portfolio section starts -->

<div class="container-fluid carriers-section">
    <div class="row carriers-portfolio-heading-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 carriers-portfolio-heading-div">
            <p class="carriers-portfolio-heading-text">Carriers In Our Portfolio</p>
        </div>
    </div>

    <div class="row carriers-portfolio-logos-row">
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 carrier-logo-1">
            <img class='carrier-logo-img' src="../../../assets/images/logos/comcast-business-logo.png" alt="Comcast Business logo" />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 carrier-logo-2">
            <img class='carrier-logo-img carrier-spectrum-logo-img' src="../../../assets/images/logos/Spectrum_Partner_Program_Horiz_RGB.png" alt="Spectrum Business logo" />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 carrier-logo-3">
            <img class='carrier-logo-img' src="../../../assets/images/logos/cox-business-logo.png" alt="Cox Business logo" />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 carrier-logo-4">
            <img class='carrier-logo-img' src="../../../assets/images/logos/viasat-logo.png" alt="Viasat logo" />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 carrier-logo-5">
            <img class='carrier-logo-img' src="../../../assets/images/logos/att.png" alt="Viasat logo" />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 carrier-logo-6">
            <img class='carrier-logo-img' src="../../../assets/images/logos/Optimum.png" alt="Viasat logo" />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 carrier-logo-7">
            <img class='carrier-logo-img' src="../../../assets/images/logos/zayo.png" alt="Viasat logo" />
        </div>

    </div>
</div>

<!-- Carriers Portfolio section ends -->


<!-- <a style="color: red; font-size: 20px;" [routerLink]="'/test-carousel'">Test Owl Carousel</a> -->
