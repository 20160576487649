<!-- Form starts -->

<div class="container-fluid cf-register-form-container"  *ngIf="!submitSuccess">

    <div class="row heading-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 heading-div">
            <p class="heading-text">{{formHeading}}</p>
            <p class="sub-heading-text">Use the form below to get in touch with a member from the CableFinder team today!</p>
        </div>
    </div>

    <div class="row cablefinder-registration-row">
        <div class='cablefinder-registration-div'>
            <form id='formCableFinderRegister' class='formCableFinderRegister' [formGroup]="cablefinderRegisterForm" (ngSubmit)="submitCFRegister()">

                <div class="row form-first-row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <select formControlName="cfregistermasteragent" class="cf-register-masteragent" id="cf-register-masteragent" [ngClass]="{ 'is-invalid': isSubmitted && f.cfregistermasteragent.errors }">
                            <option value="">Pick Your Distributor</option>
                            <option *ngFor="let masteragent of MasterAgents">{{ masteragent }}</option>
                        </select>
                        <div *ngIf="isSubmitted && f.cfregistermasteragent.errors" class="invalid-feedback">
                            <div *ngIf="f.cfregistermasteragent.errors.required">Master Agent is required</div>
                        </div>
                    </div>
                </div>
                <div class="row form-second-row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <input type="text" formControlName="cfregisterfirstname" class="cf-register-firstname" id="cf-register-firstname" placeholder="First Name" [ngClass]="{ 'is-invalid': isSubmitted && f.cfregisterfirstname.errors }" />
                        <div *ngIf="isSubmitted && f.cfregisterfirstname.errors" class="invalid-feedback">
                            <div *ngIf="f.cfregisterfirstname.errors.required">First Name is required</div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 special-column">
                        <input type="text" formControlName="cfregisterlastname" class="cf-register-lastname" id="cf-register-lastname" placeholder="Last Name" [ngClass]="{ 'is-invalid': isSubmitted && f.cfregisterlastname.errors }" />
                        <div *ngIf="isSubmitted && f.cfregisterlastname.errors" class="invalid-feedback">
                            <div *ngIf="f.cfregisterlastname.errors.required">Last Name is required</div>
                        </div>
                    </div>
                </div>
                <div class="row form-third-row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <input type="email" formControlName="cfregisteremail" class="cf-register-email" id="cf-register-email" placeholder="Email Address" [ngClass]="{ 'is-invalid': isSubmitted && f.cfregisteremail.errors }" />
                        <div *ngIf="isSubmitted && f.cfregisteremail.errors" class="invalid-feedback">
                            <div *ngIf="f.cfregisteremail.errors.required">Email is required</div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 special-column">
                        <input type="text" formControlName="cfregisterphone" class="cf-register-phone" id="cf-register-phone" placeholder="Phone" [ngClass]="{ 'is-invalid': isSubmitted && f.cfregisterphone.errors }" />
                        <div *ngIf="isSubmitted && f.cfregisterphone.errors" class="invalid-feedback">
                            <div *ngIf="f.cfregisterphone.errors.required">Phone is required</div>
                        </div>
                    </div>
                </div>
                <div class="row form-fourth-row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                        <input type="text" formControlName="cfregistercompany" class="cf-register-company" id="cf-register-company" placeholder="Company" [ngClass]="{ 'is-invalid': isSubmitted && f.cfregistercompany.errors }" />
                        <div *ngIf="isSubmitted && f.cfregistercompany.errors" class="invalid-feedback">
                            <div *ngIf="f.cfregistercompany.errors.required">Company is required</div>
                        </div>
                    </div>
                </div>
                <div class="row form-fifth-row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <input type="textarea" formControlName="cfregistermessage" class="cf-register-message" id="cf-register-message" placeholder="Message" [ngClass]="{ 'is-invalid': isSubmitted && f.cfregistermessage.errors }" />
                        <div *ngIf="isSubmitted && f.cfregistermessage.errors" class="invalid-feedback">
                            <div *ngIf="f.cfregistermessage.errors.required">Message is required</div>
                        </div>
                    </div>
                </div>
                <div class="row form-sixth-row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 divcfregisterformbtnsubmit">
                        <button type='submit' id='formcablefinderregisterbtnsubmit' class='formcablefinderregisterbtnsubmit'>Get Started With CableFinder</button>
                    </div>
                </div>

            </form>
        </div>
    </div>

</div>
<!-- Form ends -->

<!-- Thank you Message starts -->

<div class="container-fluid thank-you-message-section" *ngIf="submitSuccess">
    <div class="row thank-you-message-heading-row">
        <div class="col-12 thank-you-message-heading-div">
            <h4 class="thank-you-message-heading-text" *ngIf="getStarted">Success! <i class="fa fa-check-square thank-you-check-custom"></i> Someone From CableFinder Will Be In Touch Soon!</h4>
            <h4 class="thank-you-message-heading-text" *ngIf="nextSteps">Thanks For Submitting <img class="party-logo" src="../../../assets/images/party-logo.PNG" alt="Party logo" /></h4>
        </div>
    </div>
    <div class="row thank-you-message-desc-row">
        <div class="col-12 thank-you-message-desc-div">
            <p class="thank-you-message-desc-text">{{thankyouDescription}}</p>
        </div>
    </div>

    <div class="row thank-you-message-logos-row">
        <div class="col-12 thank-you-message-logos-div">
            <a href="http://appsmart.cablefinder.net"><img class="thank-you-message-logo" src="../../../assets/images/logos/appsmart-logo.png" alt="AppSmart logo" /></a>
            <a href="http://avant.cablefinder.net"><img class="thank-you-message-logo" src="../../../assets/images/logos/avant-logo.png" alt="Avant logo" /></a>
            <a href="http://innovative.cablefinder.net"><img class="thank-you-message-logo" src="../../../assets/images/logos/innovative-logo-agent.png" alt="Innovative Business Solutions logo" /></a>
            <a href="http://intelisys.cablefinder.net"><img class="thank-you-message-logo" src="../../../assets/images/logos/intelisys-logo.png" alt="Intelisys logo" /></a>
            <a href="http://loc8.cablefinder.net"><img class="thank-you-message-logo" src="../../../assets/images/logos/pax8-logo.png" alt="Pax8 logo" /></a>
            <!-- <a href="http://tbi.cablefinder.net"><img class="thank-you-message-logo" src="../../../assets/images/logos/tbi-logo.png" alt="TBI logo" /></a> -->
        </div>
    </div>

    <div class="row thank-you-message-heading-row">
        <div class="col-12 thank-you-message-heading-div">
            <h4 class="thank-you-message-heading-text">Not Partnered With a Master Agent?</h4>
        </div>
    </div>
    <div class="row thank-you-message-desc-row">
        <div class="col-12 thank-you-message-desc-div">
            <p class="thank-you-message-desc-text">{{notPartneredDescription}}</p>
        </div>
    </div>
</div>

<!-- Thank you Message ends -->
