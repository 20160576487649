import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-deal-or-no-deal-page',
  templateUrl: './blog-deal-or-no-deal-page.component.html',
  styleUrls: ['./blog-deal-or-no-deal-page.component.css']
})
export class BlogDealOrNoDealPageComponent implements OnInit {

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("CableFinder: The Difference Between a Deal, or No Deal");
  }

}
