import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-welcome-jeff-pratt',
  templateUrl: './blog-welcome-jeff-pratt.component.html',
  styleUrls: ['./blog-welcome-jeff-pratt.component.css']
})
export class BlogWelcomeJeffPrattComponent implements OnInit {

  constructor(private titleService:Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("Welcome to the team Jeff Pratt!");
  }

}
