import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-next-steps-page',
  templateUrl: './next-steps-page.component.html',
  styleUrls: ['./next-steps-page.component.css']
})
export class NextStepsPageComponent implements OnInit {

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("Next Steps");
  }

}
