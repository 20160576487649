import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-cf-att-partnership',
  templateUrl: './blog-cf-att-partnership.component.html',
  styleUrls: ['./blog-cf-att-partnership.component.css']
})
export class BlogCfAttPartnershipComponent implements OnInit {

  constructor(private titleService:Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("CableFinder and AT&T Partnership Press Release");
  }

}
