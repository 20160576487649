import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-news-and-updates-page',
  templateUrl: './news-and-updates-page.component.html',
  styleUrls: ['./news-and-updates-page.component.css']
})
export class NewsAndUpdatesPageComponent implements OnInit {

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("News & Updates");
  }

}
