import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';  

@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.css']
})
export class AboutPageComponent implements OnInit {

  isClicked : number = 0;
  evolutionCarouselSlide : number = 0;  
  isMastermindClicked : number = 0;
  masterMindCarouselSlide : number = 0;

  @ViewChild('evolutionCarousel', { static: true })
  evolutionCarousel!: NgbCarousel;
  @ViewChild('executiveTeamCarousel', { static: true })
  executiveTeamCarousel!: NgbCarousel;

  constructor(private titleService: Title, config: NgbCarouselConfig) { 

    config.interval = 12000;  
    config.wrap = true;  
    config.keyboard = false;  
    config.pauseOnHover = false;
    config.showNavigationArrows = false;
    config.showNavigationIndicators = true; 
    config.pauseOnFocus = false; 
   
  }

  ngOnInit(): void {
    this.titleService.setTitle("About")
  }

  highlightLink(link: any){

    if(link != this.evolutionCarouselSlide){
      this.evolutionCarousel.pause();
      this.evolutionCarouselSlide = link; 
    }
    else if(link == this.evolutionCarouselSlide){
      this.evolutionCarousel.cycle();
      this.evolutionCarouselSlide = 0; 
    }
    this.isClicked = link;
    
  }

  highlightMastermindLink(link: any){
    
    if(link != this.masterMindCarouselSlide){
      this.executiveTeamCarousel.pause();
      this.masterMindCarouselSlide = link;
    }
    else if(link == this.masterMindCarouselSlide){
      this.executiveTeamCarousel.cycle();
      this.masterMindCarouselSlide = 0;
    }
    this.isMastermindClicked = link;
     
  }
  
}
