<div class="row article-title-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-title-div">
        <p class="article-title">Intelisys Channel Connect: ‘Partner Reviews,’ Yelp for the Channel, Debuts</p>
    </div>
</div>

<div class="row article-post-date-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-post-date-div">
        <p class="article-post-date">October 14,2020 <span class="author">by Lynn Haber</span></p>
    </div>
</div>

<div class="row article-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text"><span class="article-sub-heading">Master agent </span> <a href="https://intelisys.com/" target="_blank">Intelisys</a> on Wednesday introduced a community forum called “Partner Reviews.” This new communications channel allows partners and suppliers to share experiences among themselves. The company made the announcement at its Intelisys <a href="https://channelconnect.intelisys.com/" target="_blank">Channel Connect</a> (virtual) event, which it’s holding this week.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">The community platform, inspired by Intelisys partners, allows them to share ratings, reviews and feedback on their experience with suppliers. Think of it as a Yelp for partners.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">Paul Constantine is executive vice president, supplier services at <a href="https://www.channelpartnersonline.com/2020/10/05/8x8-gives-another-master-agent-platinum-status/" target="_blank">Intelisys</a>.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div testimonial">
        <p class="article-text">"Our sales partners have been asking for years to give them a way to share among the community their experiences with our suppliers,” said Constantine. “This year we made it a priority."</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">This tool is about helping partners help their end customers make decisions faster, as is speed to sale.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">Suppliers were surprisingly on board with the concept of Partner Review.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div testimonial">
        <p class="article-text">"Suppliers want the feedback as much as the <a href="https://www.channelpartnersonline.com/2020/09/24/cable-providers-honor-intelisys-jay-bradley-top-agent-partners/" target="_blank">partners</a> want to provide it," said Constantine. "Everyone wants to maximize the opportunity."</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">Participating in Partner Review is voluntary. To date, about one-half, or 70, Intelisys suppliers are participating. Also on the road map for Partner Review is opening up the platform to Intelisys sales teams.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div testimonial">
        <p class="article-text">"This demonstrates to suppliers that we wouldn’t ask them to do something that we wouldn’t do ourselves," said Constantine.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">Expect to see this enhancement in about a month.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-sub-heading">Intelisys Launches More Enablement</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">Coinciding with the launch of Partner Reviews, Intelisys, a <a href="https://www.channelpartnersonline.com/2020/09/10/scansource-partners-get-boost-in-professional-services-support/" target="_blank">ScanSource</a> company, enhanced the <a href="https://www.myintelisys.com/" target="_blank">MyIntelisys</a> Supplier Portfolio. More specifically, the improved user interface offers new options for sorting suppliers in the portfolio by technology, category and ratings. Partners will also be able to see how suppliers compare with one another, access peer reviews and supplier responses.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">Intelisys also announced the Cable Finder tool at the partner event. This is a cable finder, configuration, pricing, order and contracting tool. It automates the process of buying cable services. It reduces what was an administratively burdensome process that took a couple of weeks, down to minutes.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">This is a big benefit to partners who sell cable. The Cable Finder tool may now encourage those who haven’t previously taken advantage of the opportunity to sell cable to do so.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">Intelisys Channel Connect has attracted about 2,600 registrants. The three-day event features six education tracks. Those include business transformation, “network nirvana,” cloud evolution, Microsoft cloud services, CaaS revolution and managed security.</p>
    </div>

</div>