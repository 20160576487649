<!-- Banner section starts -->

<div class="hero-banner-bg">
    <div class="container-fluid">
        <div class="row hero-banner-row">
            <div class="col-md-6 hero-banner-div">
                <h1 class="hero-banner-heading">CableFinder Video Resources</h1>
                <p class="hero-banner-text">Check out all of the video resources CableFinder has to offer.</p>
            </div>
        </div>
    </div>    
</div>


<!-- Banner section ends -->

<!-- Video Resources starts -->

<div class="container-fluid">    
    <div class="row video-resources-heading-row">
        <div class="col-12 video-resources-heading-div">
            <h2 class="video-resources-heading-text">Video Resources</h2>
        </div>
    </div>
</div>

<!-- Video Resources ends -->

<!-- Featured Videos starts -->

<div class="container-fluid">
    <div class="row featured-videos-heading-row">
        <div class="col-12 featured-videos-heading-div">
            <p class="featured-videos-heading">Featured Videos & Commercials</p>
            <p class="featured-videos-sub-heading">Check out these quick commercials to learn a little bit more about the CableFinder tool.</p>
        </div>
    </div>
</div>

<div class="featured-videos-bg">
    <div class="container-fluid">
        <div class="row featured-videos-row">
            <div class="col-sm-12 col-md-4 featured-videos-div">
                <div class="featured-video"><iframe src="https://www.youtube.com/embed/ajHA1jOHcfo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
            </div>
            <div class="col-sm-12 col-md-4 featured-videos-div">
                <div class="featured-video"><iframe src="https://www.youtube.com/embed/9CUBrKibIIg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
            </div>
            <div class="col-sm-12 col-md-4 featured-videos-div">
                <div class="featured-video"><iframe src="https://www.youtube.com/embed/vb4Sl6kDiOE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
            </div>
        </div>
    </div>
</div>

<!-- Featured Videos ends -->

<!-- CableFinder Basics starts -->

<div class="container-fluid">
    <div class="row cablefinder-basics-heading-row">
        <div class="col-12 cablefinder-basics-heading-div">
            <p class="cablefinder-basics-heading">CableFinder Basics. Here’s How You Source, Price, and Contract</p>
            <p class="cablefinder-basics-sub-heading">Video tutorials on how to source, price, and contract for Comcast Business, Spectrum, and Cox Business.</p>
        </div>
    </div>

    <div class="row cablefinder-basics-videos-row">
        <div class="col-12 cablefinder-basics-videos-div">
            <owl-carousel-o [options]="customOptions" class="cablefinder-basics-slider">
                <ng-template carouselSlide>
                    <div class="slide cablefinder-basics-slide-div">
                        <iframe src="https://www.youtube.com/embed/3eeAZhincgI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="slide cablefinder-basics-slide-div">
                        <iframe src="https://www.youtube.com/embed/Q-JiQIpkRHA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="slide cablefinder-basics-slide-div">
                        <iframe src="https://www.youtube.com/embed/mwHxD6Hhixs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="slide cablefinder-basics-slide-div">
                        <iframe src="https://www.youtube.com/embed/PODKRF_03HY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="slide cablefinder-basics-slide-div">
                        <iframe src="https://www.youtube.com/embed/zgBgOf64ynw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="slide cablefinder-basics-slide-div">
                        <iframe src="https://www.youtube.com/embed/W1-KvXO_qtg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="slide cablefinder-basics-slide-div">
                        <iframe src="https://www.youtube.com/embed/jI5477LTZgU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="slide cablefinder-basics-slide-div">
                        <iframe src="https://www.youtube.com/embed/6rEDGa3HL78" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="slide cablefinder-basics-slide-div">
                        <iframe src="https://www.youtube.com/embed/HiNnjmBftRk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>    
</div>

<!-- CableFinder Basics ends -->

<!-- CableFinder With You starts -->

<div class="container-fluid">
    <div class="row cablefinder-with-you-heading-row">
        <div class="col-12 cablefinder-with-you-heading-div">
            <p class="cablefinder-with-you-heading">Here’s How You Can Take CableFinder With You Anywhere</p>
            <p class="cablefinder-with-you-sub-heading">Bookmark CableFinder for easy access on your mobile device, whether you have an iPhone or Android.</p>
        </div>
    </div>

    <div class="row cablefinder-with-you-videos-row">
        <div class="col-sm-12 col-md-6 cablefinder-with-you-videos-div-left">
            <div class="cablefinder-with-you-video"><iframe src="https://www.youtube.com/embed/W1-KvXO_qtg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
        </div>
        <div class="col-sm-12 col-md-6 cablefinder-with-you-videos-div-right">
            <div class="cablefinder-with-you-video"><iframe src="https://www.youtube.com/embed/jI5477LTZgU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
        </div>
    </div>
</div>

<!-- CableFinder With You starts -->

<!-- Get To Know CableFinder starts -->

<div class="container-fluid">
    <div class="row get-to-know-cablefinder-heading-row">
        <div class="col-12 get-to-know-cablefinder-heading-div">
            <p class="get-to-know-cablefinder-heading">Other Get to Know CableFinder Content</p>
            <p class="get-to-know-cablefinder-sub-heading">Get to know the team behind the tool a little bit better, and hear about what the industry thinks.</p>
        </div>
    </div>

    <div class="row get-to-know-cablefinder-videos-row">
        <div class="col-sm-12 col-md-3 get-to-know-cablefinder-videos-div">
            <div class="get-to-know-cablefinder-video"><iframe src="https://www.youtube.com/embed/OJ7-_1M4acQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
        </div>
        <div class="col-sm-12 col-md-3 get-to-know-cablefinder-videos-div">
            <div class="get-to-know-cablefinder-video"><iframe src="https://www.youtube.com/embed/9rhS0JY71jE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
        </div>
        <div class="col-sm-12 col-md-3 get-to-know-cablefinder-videos-div">
            <div class="get-to-know-cablefinder-video"><iframe src="https://www.youtube.com/embed/-j51tnmcces" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
        </div>
        <div class="col-sm-12 col-md-3 get-to-know-cablefinder-videos-div">
            <div class="get-to-know-cablefinder-video"><iframe src="https://www.youtube.com/embed/_C8jl_AplOE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
        </div>
    </div>
</div>

<!-- Get To Know CableFinder starts -->

