<div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 hero-banner">
        <p>Resources</p>
    </div>
</div>
<div class="row first-row">
    <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3"></div> -->
    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 cf-resources">
        <a [routerLink]="'/news-and-updates'" class="resources-link"><img src="../assets/images/news-updates.png" class="resource-img" /></a>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 cf-resources">
        <a [routerLink]="'/videos'" class="resources-link"><img src="../assets/images/videos.png" class="resource-img" /></a>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 cf-resources">
        <a [routerLink]="'/user-guide'" class="resources-link"><img src="../assets/images/user-guide.png" class="resource-img" /></a>
    </div>
</div>