<div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 hero-banner">
        <p>Privacy Policy</p>
    </div>
</div>

<!-- Privacy Policy heading section starts-->

<div class="row pp-heading-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pp-heading-div">
        <p class="pp-heading">CableFinder Privacy Policy</p>
        <p class="pp-sub-heading">See below, last updated 03/01/2021</p>
    </div>
</div>

<!-- Privacy Policy heading section ends-->

<!-- Privacy Policy content section starts-->

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">Innovative Software, LLC (“we”, “our”, “Company”) values its customers and partners, their personal information, their privacy and their trust. This Privacy Policy (“Policy”) explains how we collect, use and protect information gathered through our website. Specifically, this Policy applies to our collection, use, processing, storage, and transfer of information associated with Company’s website, <a href="www.cablefinder.net">www.cablefinder.net</a> (the “Site”). If there is something you do not understand about our privacy practices, please feel free to email us at <a href="mailto:support@cablefinder.net">support@cablefinder.net</a>.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">Throughout this Policy, we refer to the term “Personal Information.” “Personal Information” is data which identifies, describes, is associated with, or could be linked to an individual, such as a name, physical address, email address, telephone number, driver’s license, SSN or government-issued identification number, credit card number, date of birth, or the like.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">Personal Information does not include anonymous or aggregated data that can no longer be used to identify a specific person, even if combined with other data. Also, some of our customers are businesses, companies, institutions, or other entities rather than individuals. We do not consider business Information, such as title, employer’s name, work email, work phone number, work address, and other similar business information, to be Personal Information.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-heading">Information We Collect</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-sub-heading">Information You Provide</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">When you contact us through our Site, engage in activity on the Site, inquire about our programs, or arrange services through our Site, we may collect certain personal information from you, such as your name, email address, physical address, phone number, credit card data, and other information you may provide to us via the Site. This information might be used to communicate with you or to process a transaction. We keep this information only for as long as is reasonably necessary for the purposes for which it was collected.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">Under “Login/Register,” and “My Account” users of the Site can register to make purchases by providing login information, which we will retain to allow access to your online account. Please do not share your login details with anyone else or allow it to be disclosed to anyone else and treat this information as you would any other confidential information.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-sub-heading">Other Information We Collect</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">In addition to collecting information directly from you as described in the previous section, we also automatically gather information when you browse or view our Site. This information may include Internet Protocol (IP) address, browser type and language, Internet service provider (ISP), referring and exit websites and applications, operating system, date/time stamp, clickstream data, whether you are a first-time or a returning user, links you clicked on while in the Site, and your flow and navigation path through the Site. We use this information to make sure that the Site and its associated parts are functioning properly, that the Site is displayed properly for your equipment and software, and that we can provide you with information useful to you and your location.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">There are “do not track” or similar browser choices available to Internet users with respect to online web activity, but given the present lack of consensus of what constitutes an informed election, appropriate trigger or breadth of scope of such choice, our Site will not operate any differently when a “do not track” signal is received.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">In some of our email messages, you will find a “click-through URL” linked to content on our Site. We track this click-through data to help us determine interest in particular topics and to measure the effectiveness of our customer communications. If you prefer not to be tracked in this way, you should not click on such links.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-sub-heading">Cookies and Other Tracking Technologies</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">We use technologies such as “cookies” so we can remember certain information about you, remember your preferences, and recognize you when you return to the Site. Cookies help us understand how users use and navigate the Site. They enable us to compile aggregate data about Site traffic and Site interaction so we can improve our Site and enhance your user experience. A cookie is a text file that is stored on your device. Most browsers are set to accept cookies automatically. You can set your browser to decline all cookies automatically or to prompt you for a response each time a cookie is offered. If you decline cookies, you may find that you cannot access all the features of our Site.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">We also use log files, web beacons and pixel tags when you visit our Site. Log files track IP addresses, browser type, Internet Service Provider (ISP) identity, referring/exit pages, platform type, date/time stamp, and number of clicks. We utilize this information in our legitimate interest in administering the Site, preventing fraud, tracking website navigation in the aggregate, and gathering broad demographic information for aggregate use. Web beacons, also known as clear GIFs, are often used in combination with cookies. Often transparent, these images are part of web pages, and allow us to count users who have visited certain pages and to generate statistics about how our Site is used. They are not used to access Personal Information. Pixel tags enable us to send email messages in a format that customers can read, and they tell us whether mail has been opened. We may use this information to reduce or eliminate messages sent to customers.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-sub-heading">Use of Personal Information</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">We may use your Personal Information to further our legitimate business interests, such as to transact business, to provide information about our company and its products and services, and to communicate with you. As described above, we may use Personal Information to send you requested information, to personalize content for you, to compile, analyze, and better understand user data, demographics and behavior, and to detect, investigate, and prevent activities that may violate our policies or be illegal.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">We also use the information automatically collected about you during your use of the Site, as described above, to improve the effectiveness of our website, operations, mobile experience, and/or marketing efforts, to prevent against fraud and theft, and otherwise to protect our Site’s visitors and our business.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">Our employees and agents may view your Personal Information to perform their jobs and service your needs and the Site operations. They will have access to your Personal Information on a need-to-know basis, and they will have a contractual obligation to protect the privacy of that information.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-sub-heading">Sharing Your Personal Information</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">We do not sell any information we collect from you. We will not disclose any of your Personal Information except when we have your permission or under special circumstances, such as when we believe in good faith that the law requires it or under circumstances described in this Policy.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">We may provide your Personal Information to our partners, as outlined above, to assist us in operating the Site and transacting business and communicating with you. When we do so, we will exercise reasonable care to ensure that such partners are prohibited from using that information for any other reason and that such information is maintained securely.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">We may disclose your Personal Information if we determine that disclosure is reasonably necessary to enforce our Terms of Service, this Privacy Policy, or to otherwise protect our operations, legal rights, clients or users.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">We may release your Personal Information to others (including law enforcement) if we believe such release is reasonably necessary to comply with the law or legal process; to enforce the provisions of the Terms and Conditions of use of the website; to detect, prevent, or otherwise address fraud, security or technical issues; or to otherwise protect the rights, property or safety of the Company or others.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">We may sell, transfer and/or disclose your Personal Information as part of a business divestiture, sale, merger, transaction, or acquisition of all or a part of our business. If another company acquires our company, business, or assets, that company will possess the Personal Information collected by us and will assume the rights and obligations regarding your Personal Information as described in this Privacy Policy. In the event of an insolvency, bankruptcy, or receivership, your Personal Information may also be transferred as a business asset.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">In addition, we may share aggregate, non-individual information, incapable of identifying a particular person, with third parties for lawful purposes.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-sub-heading">Site Not Directed to Children Under 13</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">Our Site is not directed to children under age 13 and we do not knowingly collect Personal Information from children under the age of 13. Children under 13 should not provide their Personal Information to us through our Site, to customer service, or in writing. If we learn that Personal Information belonging to anyone under the age of 13 is stored on the Site, we will immediately delete such information and refrain from using it for any purpose.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-sub-heading">California Privacy Rights</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">If you are a California resident, you may have certain rights with respect to your personal information including the following rights:</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">1) the right to know, by way of our Privacy Policy and any specific inquiries you direct to us, where personal information we have about you comes from, what we use that information for, whether it is being disclosed or sold, and to whom it is being disclosed or sold;</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">2) the right to “opt-out” of the Company’s sale of your personal information to any third parties;</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">3) the right, with some exceptions, to have your personal information deleted from the Company’s possession or control; and</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">4) the right to receive equal service and pricing from the Company even if you exercise any of your privacy rights.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">California Civil Code Section 1798.83 permits users of the Site who are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, or if you have any questions or concerns about your California privacy rights, our Privacy Policy, or our use of your Personal Information, please send an e‐mail to <a href="mailto:support@cablefinder.net">support@cablefinder.net</a> or write to us at:</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">Attn: Privacy Administrator</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">Innovative Software LLC</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">202 6th Street, Suite 402</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">Castle Rock, CO 80104</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">For all California residents, any such inquiries shall be responded to within forty-five (45) days and at no cost to you. Company will use information you have provided to us in connection with your activity on the Site to verify your identity in connection with any inquiries related to your rights noted in this Section.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">If you are a California resident under the age of 18, and a registered user of any Site where this policy is posted, California Business and Professions Code Section 22581 permits you to request and obtain removal of content or information you have publicly posted. To make such a request, please send an email with a detailed description of the specific content or information to <a href="mailto:support@cablefinder.net">support@cablefinder.net</a>. Please be aware that such a request does not ensure complete or comprehensive removal of the content or information you have posted and that there may be circumstances in which the law does not require or allow removal even if requested.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">If you have questions or concerns about these rights and how they relate to this Privacy Policy or the Site, please direct your inquiries to <a href="mailto:support@cablefinder.net">support@cablefinder.net</a> or write to us at:</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">Attn: Privacy Administrator</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">Innovative Software LLC</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">202 6th Street, Suite 402</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">Castle Rock, CO 80104</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-sub-heading">Links to Other Websites</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">While you are using the Site, you may be linked or directed to other third-party websites that are beyond our control. These third-party websites may have privacy policies and terms of use which differ from ours. Please carefully review these policies. We are not responsible for any actions or policies of such third parties.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-sub-heading">Data Movement</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">We may maintain your Personal Information on computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">We process data, including Personal Information, in the United States. We transfer your Personal Information to another organization or a country only with your consent or to perform a contract with you, and consistent with the practices described in this Privacy Policy.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-sub-heading">Security Measures</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">We maintain a variety of industry-standard security measures, including physical, electronic and procedural safeguards. For instance, we use SSL encryption technology for all credit card numbers, passwords, and registration information you provide us. No system or Site can be completely secure, so we do not promise, and you should not expect, that your Personal Information, searches, or other communications will always remain secure.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-sub-heading">Your Access and Consent to Use of your Personal Information</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">If you believe that the Personal Information that we have collected about you is incomplete, inaccurate, or not up to date, or if you would like to review and/or request changes or deletion of that information, you can contact our Webmaster.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">If you do not consent or want to withdraw your consent for us to collect, use, or disclose your Personal Information as described in this Policy, please contact our Webmaster. In your request, please identify the Personal Information at issue and the collection, use, or disclosure you wish to stop.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">If you agree to receive future marketing communications directly from a third party through our Site, you will need to contact that party to opt-out of such communications. This process may be outlined on that party's privacy policy.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-sub-heading">Changes to this Privacy Policy</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">We reserve the right to modify this Privacy Policy at any time. Changes and clarifications will take effect immediately upon their posting on the Site and we will change the “Last Modified” date below.</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-sub-heading">Questions and Contact Information</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">If you would like to: access, correct, amend or delete any Personal Information we have about you, register a complaint, or simply request more information, you may contact us at:</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">Attn: Privacy Administrator</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">Innovative Software LLC</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">202 6th Street, Suite 402</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">Castle Rock, CO 80104</p>
    </div>
</div>

<div class="row pp-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pp-content-div">
        <p class="content-text">Last Modified: 3/5/2021</p>
    </div>
</div>



<!-- Privacy Policy content section ends-->