import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-intelisys-channel-connect-page',
  templateUrl: './blog-intelisys-channel-connect-page.component.html',
  styleUrls: ['./blog-intelisys-channel-connect-page.component.css']
})
export class BlogIntelisysChannelConnectPageComponent implements OnInit {

  constructor(private titleService:Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("Intelisys Channel Connect: ‘Partner Reviews,’ Yelp for the Channel, Debuts")
  }

}
