<!-- Banner section starts -->

<div class="hero-banner-bg">
    <div class="container-fluid">
        <div class="row hero-banner-row">
            <div class="col-md-6 hero-banner-div">
                <h1 class="hero-banner-heading">CableFinder User Guide & FAQ</h1>
                <p class="hero-banner-text">Looking to get your hands on a CableFinder user guide, or have any questions you need answers to? Check out all the info below.</p>
            </div>
        </div>
    </div>
</div>


<!-- Banner section ends -->

<!-- Latest CableFinder User Guides starts -->
<div>
    <div class="latest-cablefinder-user-guides-heading-div">
        <h2 class="latest-cablefinder-user-guides-heading-text">View our latest CableFinder User Guides</h2>
    </div>
    <div class="latest-cablefinder-user-guides-div">
        <div class="latest-cablefinder-user-guides-left-div">
            <div class="latest-cablefinder-user-guides-buttons-heading">
                <span class="latest-cablefinder-user-guides-buttons-heading-text">Providers</span>
            </div>
            <div class="latest-cablefinder-user-guides-button-div">
                <button class="latest-cablefinder-user-guides-button"
                    (click)="openPdf('Suppliers in CableFinder - Updated Sept. 2024.pdf')">Suppliers In CableFinder</button>
            </div>
            <div class="latest-cablefinder-user-guides-button-div">
                <button class="latest-cablefinder-user-guides-button"
                    (click)="openPdf('Comcast in CableFinder.pdf')">Comcast</button>
            </div>
            <div class="latest-cablefinder-user-guides-button-div">
                <button class="latest-cablefinder-user-guides-button"
                    (click)="openPdf('Cox Business in CableFinder.pdf')">Cox Business</button>
            </div>
            <div class="latest-cablefinder-user-guides-button-div">
                <button class="latest-cablefinder-user-guides-button" (click)="openPdf('Optimum in CableFinder.pdf')">
                    Optimum</button>
            </div>
            <div class="latest-cablefinder-user-guides-button-div">
                <button class="latest-cablefinder-user-guides-button"
                    (click)="openPdf('Spectrum Buyflow in CableFinder.pdf')">Spectrum Buyflow</button>
            </div>
            <div class="latest-cablefinder-user-guides-button-div">
                <button class="latest-cablefinder-user-guides-button"
                    (click)="openPdf('Spectrum Enterprise in CableFinder.pdf')">Spectrum Enterprise</button>
            </div>
            <div class="latest-cablefinder-user-guides-button-div">
                <button class="latest-cablefinder-user-guides-button"
                    (click)="openPdf('Zayo in CableFinder.pdf')">Zayo</button>
            </div>
            <div class="latest-cablefinder-user-guides-button-div">
                <button class="latest-cablefinder-user-guides-button"
                    (click)="openPdf('AT&T ABF in CableFinder.pdf')">AT&T ABF</button>
            </div>
            <div class="latest-cablefinder-user-guides-button-div">
                <button class="latest-cablefinder-user-guides-button"
                    (click)="openPdf('ACC AIA in CableFinder.pdf')">ACC AIA</button>
            </div>
            <div class="latest-cablefinder-user-guides-button-div">
                <button class="latest-cablefinder-user-guides-button"
                    (click)="openPdf('AT&T ADI in CableFinder.pdf')">AT&T ADI</button>
            </div>
            <div class="latest-cablefinder-user-guides-button-div">
                <button class="latest-cablefinder-user-guides-button"
                    (click)="openPdf('ACC ADI in CableFinder.pdf')">ACC ADI</button>
            </div>
        </div>
        <div class="latest-cablefinder-user-guides-right-div">
            <div class="latest-cablefinder-user-guides-buttons-heading">
                <span class="latest-cablefinder-user-guides-buttons-heading-text">General Instructions</span>
            </div>
            <div class="latest-cablefinder-user-guides-button-div">
                <button class="latest-cablefinder-user-guides-button"
                    (click)="openPdf('Channel Manager Engagement in CableFinder.pdf')"> Channel Manager
                    Engagement</button>
            </div>
            <div class="latest-cablefinder-user-guides-button-div">
                <button class="latest-cablefinder-user-guides-button"
                    (click)="openPdf('Contract Upload in CableFinder.pdf')"> Contract Upload</button>
            </div>
            <div class="latest-cablefinder-user-guides-button-div">
                <button class="latest-cablefinder-user-guides-button"
                    (click)="openPdf('Serviceability Results in CableFinder.pdf')">Serviceability Results</button>
            </div>
        </div>
    </div>
</div>
<!-- Latest CableFinder User Guides ends -->

<!-- FAQs starts -->

<div class="container-fluid">
    <div class="row faqs-heading-row">
        <div class="col-12 faqs-heading-div">
            <h2 class="faqs-heading-text">Frequently Asked CableFinder Questions</h2>
            <p class="faqs-sub-heading-text">We’ve listed all of the frequently asked questions we usually receive. If you still can’t find your answer, please get in touch with a member from our team via <a class="faqs-mailto-link" href="mailto:Sales@CableFinder.net">Sales@CableFinder.net</a></p>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row faqs-row">        
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 faqs-div">
            <div class="faqs-question-div" (click)="question1Hidden = !question1Hidden">
                <i class="{{ question1Hidden ? 'fa fa-angle-right' : 'fa fa-angle-down' }} custom-faqs-arrow"></i><span class="faqs-question-text">How is this different from other tools?</span>
            </div>
            <div class="faqs-answer-div" [hidden]="question1Hidden">
                <p class="faqs-answer-text">CableFinder is different than any other serviceability tool because it enables you to price and contract services without ever having to send an email to a Carrier or Telecom Services Distributor.</p>
            </div>
        </div>       
    </div>
    
    <div class="row faqs-row">        
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 faqs-div">
            <div class="faqs-question-div" (click)="question2Hidden = !question2Hidden">
                <i class="{{ question2Hidden ? 'fa fa-angle-right' : 'fa fa-angle-down' }} custom-faqs-arrow"></i><span class="faqs-question-text">How accurate is the information being provided?</span>
            </div>
            <div class="faqs-answer-div" [hidden]="question2Hidden">
                <p class="faqs-answer-text">CableFinder has direct API integration into Carrier portals meaning it is providing you with real-time information as if you were going to the Carrier directly. On the results page, CableFinder reverse engineers the build calculations the Carrier uses to determine the minimum MRC required to fund the build. In the pricing catalog, you’ll see the most heavily discounted price including promotions without having to go to a Finance Department for additional approvals.</p>
            </div>
        </div>
    </div>
    
    <div class="row faqs-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 faqs-div">
            <div class="faqs-question-div" (click)="question3Hidden = !question3Hidden">
                <i class="{{ question3Hidden ? 'fa fa-angle-right' : 'fa fa-angle-down' }} custom-faqs-arrow"></i><span class="faqs-question-text">What do I do if CableFinder isn’t loading results correctly?</span>
            </div>
            <div class="faqs-answer-div" [hidden]="question3Hidden">
                <p class="faqs-answer-text">Try clearing your browsing history, logging out of the portal, and logging back in. If you are still having trouble, email <a href="mailto:support@cablefinder.net">support@cablefinder.net</a></p>
            </div>
        </div>
    </div>
    
    <div class="row faqs-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 faqs-div">
            <div class="faqs-question-div" (click)="question4Hidden = !question4Hidden">
                <i class="{{ question4Hidden ? 'fa fa-angle-right' : 'fa fa-angle-down' }} custom-faqs-arrow"></i><span class="faqs-question-text">Why don’t I see an address appear when I type a business name in the search bar?</span>
            </div>
            <div class="faqs-answer-div" [hidden]="question4Hidden">
                <p class="faqs-answer-text">That business address is not registered with Google. Instead of the business name, type in the full USPS address. You may also recommend to the customer that they create a free profile on <a href="https://www.google.com/business/business-profile/" target="_blank" nofollow>Google My Business</a> which will pull up the business in CableFinder and also improve their overall search engine optimization.</p>
            </div>
        </div>
    </div>
    
    <div class="row faqs-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 faqs-div">
            <div class="faqs-question-div" (click)="question5Hidden = !question5Hidden">
                <i class="{{ question5Hidden ? 'fa fa-angle-right' : 'fa fa-angle-down' }} custom-faqs-arrow"></i><span class="faqs-question-text">What do I do if my address isn’t serviced by one of the Carriers in the CableFinder portfolio?</span>
            </div>
            <div class="faqs-answer-div" [hidden]="question5Hidden">
                <p class="faqs-answer-text">While we work to bring more Carriers into the portfolio, you may use the Manual Request feature in CableFinder. Fill in the appropriate fields on the Manual Request page and specify in the Notes section pertinent information about the services being requested. Additionally, you may work with your Telecom Services Distributor back office team directly.</p>
            </div>
        </div>
    </div>
    
    <div class="row faqs-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 faqs-div">
            <div class="faqs-question-div" (click)="question6Hidden = !question6Hidden">
                <i class="{{ question6Hidden ? 'fa fa-angle-right' : 'fa fa-angle-down' }} custom-faqs-arrow"></i><span class="faqs-question-text">What is MRC? What is NRC?</span>
            </div>
            <div class="faqs-answer-div" [hidden]="question6Hidden">
                <p class="faqs-answer-text">MRC = Monthly Recurring Charges; NRC = Non-recurring Charges</p>
            </div>
        </div>
    </div>
    
    <div class="row faqs-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 faqs-div">
            <div class="faqs-question-div" (click)="question7Hidden = !question7Hidden">
                <i class="{{ question7Hidden ? 'fa fa-angle-right' : 'fa fa-angle-down' }} custom-faqs-arrow"></i><span class="faqs-question-text">Can I brand the Quote with my own company logo?</span>
            </div>
            <div class="faqs-answer-div" [hidden]="question7Hidden">
                <p class="faqs-answer-text">Yes, this is a newly added feature in 2021! Send a high-resolution company logo to your Telecom Services Distributor back office team and they will update your profile so that all quotes will be branded with your company logo.</p>
            </div>
        </div>
    </div>
    
    <div class="row faqs-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 faqs-div">
            <div class="faqs-question-div" (click)="question8Hidden = !question8Hidden">
                <i class="{{ question8Hidden ? 'fa fa-angle-right' : 'fa fa-angle-down' }} custom-faqs-arrow"></i><span class="faqs-question-text">Why do I have to confirm there is no unit or suite number when generating a contract?</span>
            </div>
            <div class="faqs-answer-div" [hidden]="question8Hidden">
                <p class="faqs-answer-text">One of the most common reasons for a rejected order is due to a missing or inaccurate suite number. CableFinder is decreasing the likelihood of an order being rejected for you!</p>
            </div>
        </div>
    </div>
    
    <div class="row faqs-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 faqs-div">
            <div class="faqs-question-div" (click)="question9Hidden = !question9Hidden">
                <i class="{{ question9Hidden ? 'fa fa-angle-right' : 'fa fa-angle-down' }} custom-faqs-arrow"></i><span class="faqs-question-text">Can I use my own DocuSign license to use the Sign Contract feature?</span>
            </div>
            <div class="faqs-answer-div" [hidden]="question9Hidden">
                <p class="faqs-answer-text">If you would like to use your own DocuSign license, use the Generate Contract button instead of Sign Contract.</p>
            </div>
        </div>
    </div>
    
    <div class="row faqs-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 faqs-div">
            <div class="faqs-question-div" (click)="question10Hidden = !question10Hidden">
                <i class="{{ question10Hidden ? 'fa fa-angle-right' : 'fa fa-angle-down' }} custom-faqs-arrow"></i><span class="faqs-question-text">Why are my returned results on a multi-site spreadsheet blank?</span>
            </div>
            <div class="faqs-answer-div" [hidden]="question10Hidden">
                <p class="faqs-answer-text">It’s possible the file was not uploaded correctly. Check that you uploaded the file in CSV format, not Excel. Also, make sure there is a Location Name associated with each address.</p>
            </div>
        </div>
    </div>
    
    <div class="row faqs-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 faqs-div">
            <div class="faqs-question-div" (click)="question11Hidden = !question11Hidden">
                <i class="{{ question11Hidden ? 'fa fa-angle-right' : 'fa fa-angle-down' }} custom-faqs-arrow"></i><span class="faqs-question-text">How do I change my password or profile information?</span>
            </div>
            <div class="faqs-answer-div" [hidden]="question11Hidden">
                <p class="faqs-answer-text">Hover over your username in the top right-hand corner of the screen and choose View Profile or Change Password.</p>
            </div>
        </div>
    </div>
</div>

<!-- FAQs ends -->


<!-- User Question Form starts -->

<div class="user-question-form-section-bg">
    <div class="container-fluid">
        <div class="row user-question-form-heading-row">
            <div class="col-12 user-question-form-heading-div">
                <h3 class="user-question-form-heading-text">Can’t Find the Answer?</h3>
                <p class="user-question-form-sub-heading-text">You can also submit your own questions via the form below</p>
            </div>
        </div>
        <div class="row user-question-form-row">
            <div class="col-12 user-question-form-div">
                <form id='form-user-question' class='form-user-question' [formGroup]="submitQuestionForm" (ngSubmit)="submitUserQuestion()">
                    <div class="container-fluid">
                        
                        <div class="row form-first-row">
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-user-question-field">
                                <input type="text" formControlName="cfsubmitquestionfirstname" class="form-user-question-firstname" id="form-user-question-firstname" placeholder="First Name" [ngClass]="{ 'is-invalid': isSubmitted && q.cfsubmitquestionfirstname.errors }" />
                                <div *ngIf="isSubmitted && q.cfsubmitquestionfirstname.errors" class="invalid-feedback">
                                    <div *ngIf="q.cfsubmitquestionfirstname.errors.required">First Name is required</div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-user-question-field">
                                <input type="text" formControlName="cfsubmitquestionlastname" class="form-user-question-lastname" id="form-user-question-lastname" placeholder="Last Name" [ngClass]="{ 'is-invalid': isSubmitted && q.cfsubmitquestionlastname.errors }" />
                                <div *ngIf="isSubmitted && q.cfsubmitquestionlastname.errors" class="invalid-feedback">
                                    <div *ngIf="q.cfsubmitquestionlastname.errors.required">Last Name is required</div>
                                </div>
                            </div>
                        </div>
                    
                        <div class="row form-second-row">
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-user-question-field">
                                <input type="email" formControlName="cfsubmitquestionemail" class="form-user-question-email" id="form-user-question-email" placeholder="Email Address" [ngClass]="{ 'is-invalid': isSubmitted && q.cfsubmitquestionemail.errors }" />
                                <div *ngIf="isSubmitted && q.cfsubmitquestionemail.errors" class="invalid-feedback">
                                    <div *ngIf="q.cfsubmitquestionemail.errors.required">Email is required</div>
                                    <div *ngIf="q.cfsubmitquestionemail.errors.email">Provide a valid email address</div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-user-question-field">
                                <input type="text" formControlName="cfsubmitquestioncompany" class="form-user-question-businessname" id="form-user-question-businessname" placeholder="Company Name" [ngClass]="{ 'is-invalid': isSubmitted && q.cfsubmitquestioncompany.errors }" />
                                <div *ngIf="isSubmitted && q.cfsubmitquestioncompany.errors" class="invalid-feedback">
                                    <div *ngIf="q.cfsubmitquestioncompany.errors.required">Company Name is required</div>
                                </div>
                            </div>
                        </div>
                    
                        <div class="row form-third-row">
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-user-question-field">
                                <select formControlName="cfsubmitquestionmasteragent" class="cf-demo-day-register-masteragent" id="cf-demo-day-register-masteragent" [ngClass]="{ 'is-invalid': isSubmitted && q.cfsubmitquestionmasteragent.errors }" >
                                    <option value="">What Distributor Referred You?</option>
                                    <option *ngFor="let masteragent of MasterAgents">{{ masteragent }}</option>
                                </select>
                                <div *ngIf="isSubmitted && q.cfsubmitquestionmasteragent.errors" class="invalid-feedback">
                                    <div *ngIf="q.cfsubmitquestionmasteragent.errors.required">Telecom Services Distributor is required</div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-user-question-field">
                                <input type="text" formControlName="cfsubmitquestionphone" class="form-user-question-phone" id="form-user-question-phone" placeholder="Phone Number" [ngClass]="{ 'is-invalid': isSubmitted && q.cfsubmitquestionphone.errors }" />
                                <div *ngIf="isSubmitted && q.cfsubmitquestionphone.errors" class="invalid-feedback">
                                    <div *ngIf="q.cfsubmitquestionphone.errors.required">Phone is required</div>
                                </div>
                            </div>
                        </div>
                    
                        <div class="row form-fourth-row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-user-question-field">
                                <textarea formControlName="cfsubmitquestionmessage" class="form-user-question-message" id="form-user-question-message" placeholder="Type Your Question Here"></textarea>
                            </div>
                        </div>  
                        
                        <div class="row form-fifth-row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-user-question-btn-submit-div">
                                <button type='submit' id='form-user-question-btn-submit' class='form-user-question-btn-submit'>Submit Your Question</button>
                            </div>
                        </div>
                
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


<!-- User Question Form ends -->