<div class="row article-title-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-title-div">
        <p class="article-title">Jed Kenzy on the “Coffee With Craig and Kevin” Podcast to Talk CableFinder</p>
    </div>
</div>

<div class="row article-post-date-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-post-date-div">
        <p class="article-post-date">March 19,2020 <span class="author">by Jed Kenzy</span></p>
    </div>
</div>

<div class="row article-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">Innovative’s own Jed Kenzy recently conference-called in to talk CableFinder on the Coffee With Craig and Kevin podcast! In Episode 73, Jed got to talk about Innovative’s relationships with the big cable companies and how CableFinder is changing the game for partners doing cable deals.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">He was joined by Rob Mesmer, channel chief with CBTS, who discussed his company’s venture into SD-WAN.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">Listen to the podcast in full below.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div add-space">
        <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/768300751&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/cp-online" title="Channel Partners Online" target="_blank" style="color: #cccccc; text-decoration: none;">Channel Partners Online</a> · <a href="https://soundcloud.com/cp-online/coffee-with-craig-kevin-episode-no-73-cbts-innovative-business-solutions" title="Coffee with Craig &amp; Kevin Episode No. 73: CBTS, Innovative Business Solutions" target="_blank" style="color: #cccccc; text-decoration: none;">Coffee with Craig &amp; Kevin Episode No. 73: CBTS, Innovative Business Solutions</a></div>
    </div>

</div>