import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule,FormGroup, Validators } from '@angular/forms';
import { CfHubSpotService } from '../../services/cf-hubspot.service';
import { Router } from '@angular/router';
import { CfSalesforceService } from '../../services/cf-salesforce.service';
import { ToastService } from 'src/app/services/toast-service';

@Component({
  selector: 'app-cf-register-demo-day-form',
  templateUrl: './cf-register-demo-day-form.component.html',
  styleUrls: ['./cf-register-demo-day-form.component.css']
})
export class CfRegisterDemoDayFormComponent implements OnInit {
  cablefinderDemoDayRegisterForm = new FormGroup({});
  isSubmitted = false;
  /* Master Agents List */
  MasterAgents: any = ['AppDirect', 'Avant', 'Innovative Business Solutions', 'Intelisys', 'Pax 8', 'Telarus', 'None/Not Listed'];
  //DemoSlots: any = ['8am PST / 11am EST','10am PST / 1pm EST','1pm PST / 4pm EST','3pm PST / 6pm EST'];

  constructor(public fb: FormBuilder, private cfRegister: CfHubSpotService, public cfSalesforceService: CfSalesforceService, public toastService: ToastService) { }

  ngOnInit(): void {
    this.cablefinderDemoDayRegisterForm = this.fb.group({      
      "cfdemoregisterfirstname" : ["", Validators.required],
      "cfdemoregisterlastname" : ["", Validators.required],
      "cfdemoregisteremail" : ["", [ Validators.required, Validators.email ]],
      "cfdemoregistercompany" : ["", Validators.required],
      "cfdemoregistermasteragent" : ["", Validators.required],
      "cfdemoregisterphone" : ["", Validators.required]
      //"cfdemoregisterdemoslot" : ["", Validators.required]  
    })
  }

  // convenience getter for easy access to form fields
  get d() { return this.cablefinderDemoDayRegisterForm.controls; }

  submitDemoDayRegister(){
    this.isSubmitted = true;
    if(this.cablefinderDemoDayRegisterForm.valid){
      let payload = {
        Distributor: this.cablefinderDemoDayRegisterForm.controls['cfdemoregistermasteragent'].value,
        FirstName: this.cablefinderDemoDayRegisterForm.controls['cfdemoregisterfirstname'].value,
        LastName: this.cablefinderDemoDayRegisterForm.controls['cfdemoregisterlastname'].value,
        Email: this.cablefinderDemoDayRegisterForm.controls['cfdemoregisteremail'].value,
        Phone: this.cablefinderDemoDayRegisterForm.controls['cfdemoregisterphone'].value,
        Company: this.cablefinderDemoDayRegisterForm.controls['cfdemoregistercompany'].value,
        Message: ''
      }

      this.cfSalesforceService.sendCFRegisterFormRequest(payload)
      .subscribe((res) => {
        this.toastService.show(res?.response, { classname: 'bg-success text-light', delay: 10000 });
        // alert("Thank you for requesting more information about hosting an event with CableFinder.\r\nSomeone from the CableFinder team will be reaching out to you shortly.");
        this.cablefinderDemoDayRegisterForm.reset();
      },
      (error) => {          
        this.toastService.show(error.error?.response, { classname: 'bg-danger text-light', delay: 10000 }); 
        // alert("Error in form submission! Kindly re-submit with valid data. Thank you.");     
        throw error;
      })
      this.isSubmitted = false;
    }
  }
}
