<div class="row article-title-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-title-div">
        <p class="article-title">Cablefinder And AT&T Business Services Advance Telecom Accessibility With Cutting Edge Features For Platform Users</p>
    </div>
</div>

<div class="row article-post-date-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-post-date-div">
        <p class="article-post-date">March 12th,2024</p>
    </div>
</div>

<div class="row article-content-row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">Castle Rock, CO - CableFinder, in collaboration with AT&T Business Services, has launched groundbreaking
            features designed to enhance the way businesses engage with telecom services. With the recent
            introduction of Serviceability to Order Placement, CableFinder is taking a leap forward in simplifying and
            streamlining the telecommunications experience.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">Earlier this year, CableFinder successfully launched the Serviceability feature for AT&T Business
            Services, enabling users to check availability quickly and efficiently. This marks the start of a new era in
            ubiquitous telecom accessibility. CableFinder is dedicated to further enhancing its capabilities to provide an
            all-encompassing solution for businesses with the launch of Transactional Pricing, Quoting, Contracting and
            Order Placement, offering a seamless end-to-end experience for users.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">To empower businesses with the most up-to-date information, CableFinder is introducing
            Transactional Pricing. Users gain access to real-time pricing information for all of the AT&T Business and ACC
            Business products currently integrated into the platform, ensuring they can make informed decisions about
            their telecom needs.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">CableFinder's innovative approach empowers users to automate the order placement process and
            email notifications for all carriers. In addition to up to the minute pricing, end users have the ability to
            generate Letters of Authorization and contracts for AT&T's product portfolio and for some products, ability
            to perform contract upload, directly within the platform. By doing so, CableFinder reduces the reliance
            on manual tasks typically performed by the TSD back office, resulting in significant time and cost savings.
            Furthermore, the automation eliminates the potential for errors, enhancing the overall accuracy and
            efficiency of the telecom service procurement process.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">“This marks a pivotal moment for our company and the entire cable and telecommunications
            industry. AT&T in CableFinder is a game-changer that reflects our commitment to innovation, customer-
            centricity, and the future of connectivity,” said CableFinder Co-founder Jed Kenzy. “By bringing AT&T’s
            cutting edge services into our platform, we are providing our users with an unprecedented level of choice,
            convenience, and connectivity.”</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">“CableFinder has always been about simplifying the complex landscape of cable and internet
            services, making it easier for partners and end user consumer businesses to find the solutions that best
            suit their needs. With AT&T on board, we are taking that mission to the next level, offering a broader range
            of options and empowering our users with even more control over their connectivity decisions,” said
            CableFinder Co-founder Mike Trede.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">“AT&T and ACC Business continually look for ways to be easier to do business with for indirect
            channels. Going live with CableFinder allows AT&T to dramatically improve the experience for partners,
            allowing us to be where the partners are when they are exploring options for their customers,” said Chris
            Jones, AVP Sales, AT&T Alliance Channel. “We look forward to continuing to work with CableFinder on what is
            next.”</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">CableFinder and AT&T Business Services are dedicated to continuously improving and simplifying
            the telecom experience for businesses of all sizes. These new features are just the beginning, as both
            organizations are committed to delivering innovative solutions that empower businesses to connect,
            communicate, and succeed.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">For more information about CableFinder and these exciting new features, please visit <span class="article-sub-heading"> <a href="https://www.cablefinder.net/" target="_blank">www.cablefinder.net</a>.</span></p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-sub-heading">About CableFinder</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">CableFinder is the trusted industry standard platform for advanced quoting and contracting with all of
            the major TSDs and their partners. With a focus on efficiency, technological advancement, and customer
            experience, CableFinder's API-driven platform streamlines operations, empowering partners to accelerate
            growth and success across a wide range of business services providers.</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-sub-heading">About AT&T</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">We help more than 100 million U.S. families, friends and neighbors, plus nearly 2.5 million businesses,
            connect to greater possibility. From the first phone call 140+ years ago to our 5G wireless and multi-gig
            internet offerings today, we @ATT innovate to improve lives. For more information about AT&T Inc. (NYSE:T),
            please visit us at <span class="article-sub-heading"> <a href="https://about.att.com/" target="_blank">about.att.com</a>.</span> Investors can learn more at <span class="article-sub-heading"> <a href="https://investors.att.com/" target="_blank">investors.att.com</a>.</span></p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">To learn more about AT&T Business Services, please visit <span class="article-sub-heading"> <a href="https://business.att.com/" target="_blank">business.att.com</a>.</span></p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-sub-heading">Contacts</p>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 article-content-div">
        <p class="article-text">Happy Marino, Sr. Director, Marketing, happy@cablefinder.net, 203-979-3685</p>
    </div>


</div>