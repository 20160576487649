import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { IndexPageComponent } from './pages/index-page/index-page.component';
import { EventsPageComponent } from './pages/events-page/events-page.component';
import { ResourcesPageComponent } from './pages/resources-page/resources-page.component';
import { GetStartedPageComponent } from './pages/get-started-page/get-started-page.component';
import { NextStepsPageComponent } from './pages/next-steps-page/next-steps-page.component';
import { NewsAndUpdatesPageComponent } from './pages/news-and-updates-page/news-and-updates-page.component';
import { CfVideosPageComponent } from './pages/cf-videos-page/cf-videos-page.component';
import { UserGuidePageComponent } from './pages/user-guide-page/user-guide-page.component';
import { BlogDealOrNoDealPageComponent } from './pages/blog-deal-or-no-deal-page/blog-deal-or-no-deal-page.component';
import { BlogEnjoyYourSummerPageComponent } from './pages/blog-enjoy-your-summer-page/blog-enjoy-your-summer-page.component';
import { BlogEasyCablefinderDealPageComponent } from './pages/blog-easy-cablefinder-deal-page/blog-easy-cablefinder-deal-page.component';
import { BlogCoffeeWithCraigAndKevinPageComponent } from './pages/blog-coffee-with-craig-and-kevin-page/blog-coffee-with-craig-and-kevin-page.component';
import { BlogIbsIntroducesCablefinderToolPageComponent } from './pages/blog-ibs-introduces-cablefinder-tool-page/blog-ibs-introduces-cablefinder-tool-page.component';
import { BlogNewServiceCablefinderPageComponent } from './pages/blog-new-service-cablefinder-page/blog-new-service-cablefinder-page.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { PrivacyPolicyPageComponent } from '../app/pages/privacy-policy-page/privacy-policy-page.component';
import { BlogIntelisysChannelConnectPageComponent } from './pages/blog-intelisys-channel-connect-page/blog-intelisys-channel-connect-page.component'
import { BlogWelcomeJeffPrattComponent } from './pages/blog-welcome-jeff-pratt/blog-welcome-jeff-pratt.component'
import { UserGuidesPageComponent } from './pages/user-guides-page/user-guides-page.component';
import { BlogCfAttPartnershipComponent } from './pages/blog-cf-att-partnership/blog-cf-att-partnership.component';
import { IntroducingZayoPricingAndContractGenerationComponent } from './pages/introducing-zayo-pricing-and-contract-generation/introducing-zayo-pricing-and-contract-generation.component';
import { BlogCFandSpectrumBusinessPressReleaseComponent} from './pages/blog-press-release-for-cablefinder-website/blog-cf-and-spectrum-business-press-release.component';


const routes: Routes = [
  { path: '', component: IndexPageComponent },
  { path: 'about', component: AboutPageComponent },
  { path: 'events', component: EventsPageComponent },
  { path: 'resources', component: ResourcesPageComponent },
  { path: 'get-started', component: GetStartedPageComponent },
  { path: 'next-steps', component: NextStepsPageComponent },
  { path: 'news-and-updates', component: NewsAndUpdatesPageComponent },
  { path: 'videos', component: CfVideosPageComponent },
  { path: 'user-guide', component: UserGuidePageComponent },
  { path: 'privacy-policy', component: PrivacyPolicyPageComponent},
  { path: 'news-and-updates/deal-or-no-deal', component: BlogDealOrNoDealPageComponent },
  { path: 'news-and-updates/enjoy-your-summer-with-cablefinder', component: BlogEnjoyYourSummerPageComponent },
  { path: 'news-and-updates/easy-cablefinder-deal', component: BlogEasyCablefinderDealPageComponent },
  { path: 'news-and-updates/jed-kenzy-on-coffee-with-craig-and-kevin', component: BlogCoffeeWithCraigAndKevinPageComponent },
  { path: 'news-and-updates/ibs-introduces-cablefinder-tool', component: BlogIbsIntroducesCablefinderToolPageComponent },
  { path: 'news-and-updates/new-service-cablefinder', component: BlogNewServiceCablefinderPageComponent },
  { path: 'news-and-updates/intelisys-channel-connect', component: BlogIntelisysChannelConnectPageComponent },
  { path: 'news-and-updates/welcome-to-the-team-jeff-prat', component: BlogWelcomeJeffPrattComponent },
  { path: 'news-and-updates/cablefinder-att-partnership-press-release', component: BlogCfAttPartnershipComponent },
  { path: 'news-and-updates/zayo-pricing-and-contract-generation', component: IntroducingZayoPricingAndContractGenerationComponent },
  { path: 'news-and-updates/cablefinder-and-spectrum-business-press-release', component: BlogCFandSpectrumBusinessPressReleaseComponent },
  // { path: 'user-guides', component: UserGuidesPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
