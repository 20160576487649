<owl-carousel-o [options]="customOptions" class="testimonial-carousel">

    <ng-container>
        <ng-template carouselSlide>
            <div class="slide testimonial-slide-div">
                <p class="quotationMark"><em class="quotation">&#8220; </em></p>
                <p class="testimonial-text">CableFinder is a game-changing tool for Intelisys and our Sales Partners. With serviceability to order within minutes, CableFinder has decreased the turnaround time for our presales process exponentially, allowing for a fast, easy partner
                    experience and a higher volume output for us and our Sales Partners. This tool has revolutionized the cable experience in the channel.</p>
                <p class="testimonial-heading">Jodi Williams</p>
                <p class="testimonial-author">Cable Program Director at Intelisys, a ScanSource company</p>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="slide testimonial-slide-div">
                <p class="quotationMark"><em class="quotation">&#8220; </em></p>
                <p class="testimonial-text">CableFinder has simplified the quoting and contracting process for AVANT’s partners resulting in a seamless sales experience and higher revenue.</p>
                <p class="testimonial-heading">Shane McNamara</p>
                <p class="testimonial-author">Executive Vice President of Engineering and Operations, AVANT Communications</p>
            </div>
        </ng-template>
    </ng-container>

</owl-carousel-o>