<!-- Banner section starts -->

<div class="hero-banner-bg">
    <div class="container-fluid">
        <div class="row hero-banner-row">
            <div class="col-md-6 hero-banner-div">
                <h1 class="hero-banner-heading">CableFinder User Guides</h1>
            </div>
        </div>
    </div>
</div>

<!-- Banner section ends -->

<!-- Latest CableFinder User Guides starts -->

<div class="container-fluid">

    <div class="row latest-cablefinder-user-guides-heading-row">
        <div class="col-12 latest-cablefinder-user-guides-heading-div">
            <h2 class="latest-cablefinder-user-guides-heading-text">View our latest CableFinder User Guides</h2>
        </div>
    </div>

    <div class="row latest-cablefinder-user-guides-row">
        <div class="col-sm-12 col-md-6 latest-cablefinder-user-guides-div">
            <div class="latest-cablefinder-user-guides-button-div">
                <button class="latest-cablefinder-user-guides-button" (click)="openPdf('Suppliers in CableFinder.pdf')">SUPPLIERS IN CABLEFINDER</button>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 latest-cablefinder-user-guides-div">
            <div class="latest-cablefinder-user-guides-button-div">
                <button class="latest-cablefinder-user-guides-button" (click)="openPdf('Channel Manager Engagement Generic.pdf')">CHANNEL MANAGER ENGAGEMENT SELECTION</button>
            </div>
        </div>
    </div>
    <div class="row latest-cablefinder-user-guides-row">
        <div class="col-sm-12 col-md-6 latest-cablefinder-user-guides-div">
            <div class="latest-cablefinder-user-guides-button-div">
                <button class="latest-cablefinder-user-guides-button">COMING SOON</button>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 latest-cablefinder-user-guides-div">
            <div class="latest-cablefinder-user-guides-button-div">
                <button class="latest-cablefinder-user-guides-button">COMING SOON</button>
            </div>
        </div>
    </div>
    <div class="row latest-cablefinder-user-guides-row">
        <div class="col-sm-12 col-md-6 latest-cablefinder-user-guides-div">
            <div class="latest-cablefinder-user-guides-button-div">
                <button class="latest-cablefinder-user-guides-button">COMING SOON</button>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 latest-cablefinder-user-guides-div">
            <div class="latest-cablefinder-user-guides-button-div">
                <button class="latest-cablefinder-user-guides-button">COMING SOON</button>
            </div>
        </div>
    </div>
</div>


<!-- Latest CableFinder User Guides ends -->