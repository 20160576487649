import { Component,OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { CfHubSpotService } from '../app/services/cf-hubspot.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  signUpUpdatesForm = new FormGroup({});
  isSubmitted = false;

  constructor(public fb: FormBuilder, private cfNewsletterSubscribe: CfHubSpotService, private router: Router) { }

  rightsYear: number = new Date().getFullYear();

  ngOnInit(): void {

    this.signUpUpdatesForm = this.fb.group({
      "cfupdatesemail" : ["", Validators.required]
    })

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });

  }

  // onSubmit(){
  //   alert(this.signUpUpdatesForm.controls['cfupdatesemail'].value);
  // }

   // convenience getter for easy access to form fields
   get f() { return this.signUpUpdatesForm.controls; }

  subscribeNewsletter(){
    this.isSubmitted = true;
    if(this.signUpUpdatesForm.valid){
      var datenow = new Date();
      var utcms = Math.floor(datenow.getTime()/ 1000);

      let payload = {
        "submittedAt": utcms + '000',
        "fields": [
          {
            "name": "email",
            "value": this.signUpUpdatesForm.controls['cfupdatesemail'].value
          }
        ]
      }

      this.cfNewsletterSubscribe.sendCFNewsletterSubscribeRequest(payload)
        .subscribe(res => {
          console.log(res);
          alert("Thank you for submitting your email to stay up to date on CableFinder's new feature releases and company news.");
        },
        (error) => {
          alert("Error in form submission! Kindly re-submit with valid data. Thank you.");
          throw error;
        })
      this.isSubmitted = false;
      this.signUpUpdatesForm.reset();
    }
  }
}
