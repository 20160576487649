import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-introducing-zayo-pricing-and-contract-generation',
  templateUrl: './introducing-zayo-pricing-and-contract-generation.component.html',
  styleUrls: ['./introducing-zayo-pricing-and-contract-generation.component.css']
})
export class IntroducingZayoPricingAndContractGenerationComponent implements OnInit {

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("Exciting News for CableFinder Users: Introducing Zayo Pricing and Contract Generation");
  }

}
