import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-cf-and-spectrum-business-press-release',
  templateUrl: './blog-cf-and-spectrum-business-press-release.component.html',
  styleUrls: ['./blog-cf-and-spectrum-business-press-release.component.css']
})
export class BlogCFandSpectrumBusinessPressReleaseComponent implements OnInit {

  constructor(private titleService:Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("CableFinder and AT&T Partnership Press Release");
  }

}
