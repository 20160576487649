import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-user-guides-page',
  templateUrl: './user-guides-page.component.html',
  styleUrls: ['./user-guides-page.component.css']
})
export class UserGuidesPageComponent implements OnInit {

  constructor(private titleService: Title ) { }

  ngOnInit(): void {
    this.titleService.setTitle("User Guides");
  }
  openPdf(bookName: any) {
    const pdfUrl = `./assets/e-books/${bookName}`;
    const pdfName = bookName;
    window.open(pdfUrl, pdfName);
  }
}
